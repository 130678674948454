module.exports={
    "CJSB": {
        "icon": "assets/product-icons/CJSB.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJSB",
        "hasAssets": true,
        "modelId": "CJSB",
        "textureId": "CJSB",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar",
                    "collarAbove",
                    "collarBelow"
                ],
                "area3": [
                    "sideRight",
                    "sideLeft"
                ],
                "area4": [
                    "sleeveRight.insert",
                    "sleeveLeft.insert"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Inserts",
                "area4": "Shoulder Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": null,
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CS2": {
        "icon": "assets/product-icons/CS2.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "ribKnitCollar",
            "ribKnitCuff",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CS2",
        "hasAssets": true,
        "modelId": "CS2",
        "textureId": "CS2",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "front",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": true,
            "ribKnitCuff": true,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CS2",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CJP2": {
        "icon": "assets/product-icons/CJP2.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJP2",
        "hasAssets": true,
        "modelId": "CJP2",
        "textureId": "CJP2",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collarBack",
                    "collarFrontAbove",
                    "collarFrontBelow"
                ],
                "area3": [
                    "*.insert"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": "Inserts",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJP2",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CSUCPJ": {
        "icon": "assets/product-icons/CSUCPJ.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSUCPJ",
        "hasAssets": true,
        "modelId": "CSUCPJ",
        "textureId": "CSUCPJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.pipingFront"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collarAbove",
                    "collarBelow"
                ],
                "area3": [
                    "*.underarmInsert"
                ],
                "area4": [
                    "*.shoulderSleeveInsert"
                ],
                "area5": [
                    "*.pipingBack"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "1/8\" Front Piping",
                "area2": "Body",
                "area3": "Under-arm Inserts",
                "area4": "Shoulder and Sleeve Inserts",
                "area5": "1/8\" Back Piping",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSUCPJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CSSP": {
        "icon": "assets/product-icons/CSSP.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSSP",
        "hasAssets": false,
        "modelId": "CSSP",
        "textureId": "CSSP",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarAbove",
                    "collarBelow"
                ],
                "area3": [
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Right Sleeve",
                "area4": "Left Sleeve",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSSP",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CSMPPJ": {
        "icon": "assets/product-icons/CSMPPJ.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSMPPJ",
        "hasAssets": false,
        "modelId": "CSMPPJ",
        "textureId": "CSMPPJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collarBack",
                    "collarFrontAbove",
                    "collarFrontBelow",
                    "'button"
                ],
                "area3": [
                    "*.insert"
                ],
                "area4": [
                    "*.topSideInsert"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Under-arm Inserts",
                "area5": "Front Side Insert",
                "area6": "Back Side Insert",
                "area7": "Back Shoulder Insert"
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSMPPJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "SS1": {
        "icon": "assets/product-icons/SS1.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "SS1",
        "hasAssets": false,
        "modelId": "SS1",
        "textureId": "SS1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collarBack",
                    "collarFrontAbove",
                    "collarFrontBelow",
                    "'button"
                ],
                "area3": [
                    "*.insert"
                ],
                "area4": [
                    "*.topSideInsert"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "SS1",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CJDIP": {
        "icon": "assets/product-icons/CJDIP.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJDIP",
        "hasAssets": true,
        "modelId": "CJDIP",
        "textureId": "CJDIP",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collarBack",
                    "collarFrontAbove",
                    "collarFrontBelow"
                ],
                "area3": [
                    "*.insert"
                ],
                "area4": [
                    "*.topSideInsert"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeve Inserts",
                "area4": "Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJDIP",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CROAD": {
        "icon": "assets/product-icons/CROAD.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CROAD",
        "hasAssets": true,
        "modelId": "CROAD",
        "textureId": "CROAD",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.insertArea"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar",
                    "collarAbove",
                    "collarBelow",
                    "front.bottomSideInsert"
                ],
                "area3": [
                    "front.topSideInsert"
                ],
                "area4": [
                    "front.middleSideInsert"
                ],
                "area5": [
                    "back.topBackInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Shoulder and Sideseam Piping",
                "area2": "Body",
                "area3": "Side Inserts",
                "area4": "Insert Accents",
                "area5": "Upper Back Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CROAD",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "S9BT": {
        "icon": "assets/product-icons/S9BT.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "S9BT",
        "hasAssets": true,
        "modelId": "S9BT",
        "textureId": "S9BT",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "shoulderInsertLeft",
                    "shoulderInsertRight"
                ],
                "area4": [
                    "sleeveInsertLeft",
                    "sleeveInsertRight"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder Inserts",
                "area4": "Sleeve Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "S9BT",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CSRMT": {
        "icon": "assets/product-icons/CSRMT.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSRMT",
        "hasAssets": true,
        "modelId": "CSRMT",
        "textureId": "CSRMT",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Neck",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSRMT",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CJ2BI": {
        "icon": "assets/product-icons/CJ2BI.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP3",
            "ribKnitCollar",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJ2BI",
        "hasAssets": true,
        "modelId": "CJ2BI",
        "textureId": "CJ2BI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "placketFront",
                    "placketBack"
                ],
                "area3": [
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLeft"
                ],
                "area5": [
                    "sideInsert1",
                    "sideInsert2",
                    "sideInsert3",
                    "sideInsert4"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Right Sleeve",
                "area4": "Left Sleeve",
                "area5": "Side Inserts",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": true,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJ2BI",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CSPL": {
        "icon": "assets/product-icons/CSPL.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSPL",
        "hasAssets": false,
        "modelId": "CSPL",
        "textureId": "CSPL",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.op1"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.placket_insert"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "OP1 Piping",
                "area2": "Body",
                "area3": "Placket",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSPL",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CSSIFJ": {
        "icon": "assets/product-icons/CSSIFJ.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSSIFJ",
        "hasAssets": false,
        "modelId": "CSSIFJ",
        "textureId": "CSSIFJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Inserts",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSSIFJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CSMPFJ": {
        "icon": "assets/product-icons/CSMPFJ.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSMPFJ",
        "hasAssets": false,
        "modelId": "CSMPFJ",
        "textureId": "CSMPFJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Upper Front Side Insert",
                "area5": "Lower Front Side Insert",
                "area6": "Back Side Insert",
                "area7": "Back Shoulder Insert"
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSMPFJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CJ3F": {
        "icon": "assets/product-icons/CJ3F.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJ3F",
        "hasAssets": true,
        "modelId": "CJ3F",
        "textureId": "CJ3F",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "back"
                ],
                "area3": [
                    "frontLeft.insert",
                    "frontRight.insert"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder Inserts",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJ3F",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CJMSI": {
        "icon": "assets/product-icons/CJMSI.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJMSI",
        "hasAssets": true,
        "modelId": "CJMSI",
        "textureId": "CJMSI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "back"
                ],
                "area3": [
                    "*.topSideInsert"
                ],
                "area4": [
                    "*.bottomSideInsert"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Upper Side Insert",
                "area4": "Lower Side Insert",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJMSI",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CSBI": {
        "icon": "assets/product-icons/CSBI.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSBI",
        "hasAssets": false,
        "modelId": "CSBI",
        "textureId": "CSBI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Inserts",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSBI",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CS5": {
        "icon": "assets/product-icons/CS5.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CS5",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CS5",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CS10C": {
        "icon": "assets/product-icons/CS10C.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CS10C",
        "hasAssets": true,
        "modelId": "CS10C",
        "textureId": "CS10C",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CS10C",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "BBSS": {
        "icon": "assets/product-icons/BBSS.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BBSS",
        "hasAssets": true,
        "modelId": "BBSS",
        "textureId": "BBSS",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BBSS",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CJDI": {
        "icon": "assets/product-icons/CJDI.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJDI",
        "hasAssets": true,
        "modelId": "CJDI",
        "textureId": "CJDI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "back"
                ],
                "area3": [
                    "*.sleeveInsertFront",
                    "*.sleeveInsertBack"
                ],
                "area4": [
                    "*.sideInsertFront",
                    "*.sideInsertBack"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": "Sleeve Inserts",
                "area4": "Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJDI",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CSUCFJ": {
        "icon": "assets/product-icons/CSUCFJ.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSUCFJ",
        "hasAssets": true,
        "modelId": "CSUCFJ",
        "textureId": "CSUCFJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.pipingFront"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.underarmInsert"
                ],
                "area4": [
                    "shoulderLeft",
                    "shoulderRight",
                    "*.shoulderAndSleeveInsert",
                    "*.sleeveLeftExtension",
                    "*.sleeveRightExtension"
                ],
                "area5": [
                    "*.pipingBack"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "1/8\" Front Piping",
                "area2": "Body",
                "area3": "Under-arm Inserts",
                "area4": "Shoulder and Sleeve Inserts",
                "area5": "1/8\" Back Piping",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSUCFJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CSSF": {
        "icon": "assets/product-icons/CSSF.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSSF",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Right Sleeve",
                "area4": "Left Sleeve",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSSF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "CJI": {
        "icon": "assets/product-icons/CJI.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJI",
        "hasAssets": true,
        "modelId": "CJI",
        "textureId": "CJI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.shoulderArmInsert"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Insert",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJI",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "ADBPJ13": {
        "icon": "assets/product-icons/ADBPJ13.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2_5",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "ADBPJ13",
        "hasAssets": true,
        "modelId": "ADBPJ13",
        "textureId": "ADBPJ13",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "placketRight",
                    "placketLeft",
                    "sideInsertRight",
                    "sideInsertLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "sideInsertLower",
                    "collar"
                ],
                "area3": [
                    "backInsertLeft",
                    "backInsertRight",
                    "frontInsertRight",
                    "frontInsertLeft",
                    "sleeveInsert",
                    "sleeveInsert2"
                ],
                "area4": [
                    "back"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Front Body",
                "area3": "Side Inserts",
                "area4": "Back Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": false,
            "OP2_5": true,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ADBPJ13",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "KLBPJ": {
        "icon": "assets/product-icons/KLBPJ.jpg?2014-09-30",
        "category": "customJersey",
        "optionList": [
            "OP1",
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "KLBPJ",
        "hasAssets": true,
        "modelId": "KLBPJ",
        "textureId": "KLBPJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "back",
                    "frontLeft",
                    "frontRight",
                    "placketRight",
                    "placketLeft",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [],
                "area3": [
                    "neckInsert",
                    "collar",
                    "*.cuff"
                ],
                "area4": [
                    "frontInsertLeft",
                    "frontInsertRight",
                    "backInsertLeft",
                    "backInsertRight",
                    "sleeveInsert1",
                    "sleeveInsert2",
                    "sleeveInsert3",
                    "sleeveInsert4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck Insert & Sleeve End",
                "area4": "Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customJersey"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "embroidery",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "KLBPJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "ROAD": {
        "icon": "assets/product-icons/ROAD.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "ROAD",
        "hasAssets": true,
        "modelId": "CROAD",
        "textureId": "CROAD",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar",
                    "collarAbove",
                    "collarBelow",
                    "front.middleSideInsert",
                    "front.bottomSideInsert"
                ],
                "area2-1": [
                    "*.insertArea",
                    "front.topSideInsert",
                    "back.topBackInsert"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "ROAD"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ROAD",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RBJ2B": {
        "icon": "assets/product-icons/RBJ2B.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "RBJ2B",
        "hasAssets": true,
        "modelId": "CJDIP",
        "textureId": "CJDIP",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collarBack",
                    "collarFrontAbove",
                    "collarFrontBelow"
                ],
                "area2-1": [
                    "*.insert",
                    "*.topSideInsert"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Inserts",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RBJ2B"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "tg"
                ],
                "youth": [
                    "tg"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RBJ2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "SSBASE": {
        "icon": "assets/product-icons/SSBASE.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "SSBASE",
        "hasAssets": true,
        "modelId": "SSBASE",
        "textureId": "SSBASE",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "insertBackRight",
                    "insertBackLeft",
                    "insertFrontLeft",
                    "insertFrontRight",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SSBASE"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "tg"
                ],
                "youth": [
                    "tg"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "SSBASE",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RJFADE": {
        "icon": "assets/product-icons/RJFADE.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "RJFADE",
        "hasAssets": true,
        "modelId": "CJ2BI",
        "textureId": "CJ2BI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "sideInsert1",
                    "sideInsert2",
                    "sideInsert3",
                    "sideInsert4",
                    "placketBack",
                    "placketFront"
                ],
                "area2-1": [
                    "sleeveRight.fade",
                    "sleeveLeft.fade",
                    "sideInsert1.fade3",
                    "sideInsert2.fade2",
                    "sideInsert3.fade2",
                    "sideInsert4.fade3",
                    "collar"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RJFADE"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "vf"
                ],
                "youth": [
                    "vf"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RJFADE",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RJ140": {
        "icon": "assets/product-icons/RJ140.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "RJ140",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RJ140"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RJ140",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RJ140A": {
        "icon": "assets/product-icons/RJ140A.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "RJ140A",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RJ140A"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RJ140A",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RBJ150": {
        "icon": "assets/product-icons/RBJ150.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "RBJ150",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RBJ150"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RBJ150",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RSJ150": {
        "icon": "assets/product-icons/RSJ150.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "RSJ150",
        "hasAssets": true,
        "modelId": "BBSS",
        "textureId": "BBSS",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RSJ150"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RSJ150",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RBBJ95": {
        "icon": "assets/product-icons/RBBJ95.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "RBBJ95",
        "hasAssets": false,
        "modelId": "CS5",
        "textureId": "CS5",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RBBJ95"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RBBJ95",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RBBJ350": {
        "icon": "assets/product-icons/RBBJ350.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "RBBJ350",
        "hasAssets": false,
        "modelId": "CS5",
        "textureId": "CS5",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RBBJ350"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RBBJ350",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RBJ167": {
        "icon": "assets/product-icons/RBJ167.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "RBJ167",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RBJ167"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "sbpt"
                ],
                "youth": [
                    "sbpt"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RBJ167",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HBJ": {
        "icon": "assets/product-icons/HBJ.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HBJ",
        "hasAssets": true,
        "modelId": "HBJ",
        "textureId": "HBJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderInsertRightFront",
                    "shoulderInsertLeftFront",
                    "shoulderInsertRightBack",
                    "shoulderInsertLeftBack",
                    "sideInsertRightFront",
                    "sideInsertLeftFront",
                    "sideInsertRightBack",
                    "sideInsertLeftBack",
                    "*.collarPiping"
                ],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "front",
                    "back",
                    "placketRight",
                    "placketLeft",
                    "collar",
                    "placketRightInside",
                    "placketLeftInside",
                    "collarInside"
                ],
                "area3": [
                    "*.insertPiping"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body, Collar, Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "HBJ"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "tg"
                ],
                "youth": [
                    "tgYouth"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HBJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHBJ",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "RTT": {
        "icon": "assets/product-icons/RTT.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "RTT",
        "hasAssets": true,
        "modelId": "CSRMT",
        "textureId": "RTT",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body, Collar, Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "RTT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "tg"
                ],
                "youth": [
                    "tgYouth"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RTT",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YRTT",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "ADBPJ13VF": {
        "icon": "assets/product-icons/ADBPJ13VF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2_5",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ADBPJ13VF",
        "hasAssets": true,
        "modelId": "ADBPJ13",
        "textureId": "ADBPJ13",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "placketRight",
                    "placketLeft",
                    "sideInsertRight",
                    "sideInsertLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "sideInsertLower",
                    "collar"
                ],
                "area3": [
                    "backInsertLeft",
                    "backInsertRight",
                    "frontInsertRight",
                    "frontInsertLeft",
                    "sleeveInsert",
                    "sleeveInsert2"
                ],
                "area4": [
                    "back"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": "jerseyBody",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Front Body",
                "area3": "Side Inserts",
                "area4": "Back Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": false,
            "OP2_5": true,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ADBPJ13VF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "ARZFBSL": {
        "icon": "assets/product-icons/ARZFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ARZFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.arzfbsl.color3"
                ],
                "area4": [
                    "*.arzfbsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Shoulder Accents and Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ARZFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YARZFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "ARZJ": {
        "icon": "assets/product-icons/ARZJ.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ARZJ",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar",
                    "*.arzj.color2"
                ],
                "area3": [
                    "*.arzj.color3"
                ],
                "area4": [
                    "*.arzj.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accent",
                "area4": "Sleeve accents",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ARZJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "BBFB": {
        "icon": "assets/product-icons/BBFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BBFB",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.bbfb.color3"
                ],
                "area4": [
                    "*.bbfb.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body / Sleeve Border",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BBFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YBBFB",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BBFBSL": {
        "icon": "assets/product-icons/BBFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BBFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "BBFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.bbfbsl.color3"
                ],
                "area4": [
                    "*.bbfbsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BBFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YBBFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BBSSVF": {
        "icon": "assets/product-icons/BBSSVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BBSSVF",
        "hasAssets": true,
        "modelId": "BBSS",
        "textureId": "BBSS",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BBSSVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CHIFB": {
        "icon": "assets/product-icons/CHIFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CHIFB",
        "hasAssets": true,
        "modelId": "CS10C",
        "textureId": "CS10C",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.chifb.color3"
                ],
                "area4": [
                    "*.chifb.color4"
                ],
                "area5": [
                    "*.chifb.color5"
                ],
                "area6": [
                    "*.chifb.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripes",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CHIFB",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CHIFBSL": {
        "icon": "assets/product-icons/CHIFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CHIFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "CHIFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.chifbsl.color3"
                ],
                "area4": [
                    "*.chifbsl.color4"
                ],
                "area5": [
                    "*.chifbsl.color5"
                ],
                "area6": [
                    "*.chifbsl.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Striptes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CHIFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YCHIFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CJDIVF": {
        "icon": "assets/product-icons/CJDIVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CJDIVF",
        "hasAssets": true,
        "modelId": "CJDI",
        "textureId": "CJDI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "back",
                    "*.placket"
                ],
                "area3": [
                    "*.sleeveInsertFront",
                    "*.sleeveInsertBack"
                ],
                "area4": [
                    "*.sideInsertFront",
                    "*.sideInsertBack"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": "standard",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": "Sleeve Inserts",
                "area4": "Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJDIVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CS10CVF": {
        "icon": "assets/product-icons/CS10CVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CS10CVF",
        "hasAssets": true,
        "modelId": "CS10C",
        "textureId": "CS10C",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CS10CVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CS5VF": {
        "icon": "assets/product-icons/CS5VF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CS5VF",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CS5VF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CSSFVF": {
        "icon": "assets/product-icons/CSSFVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CSSFVF",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "jerseyBody",
                "area4": "jerseyBody",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Right Sleeve",
                "area4": "Left Sleeve",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSSFVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "DFFB": {
        "icon": "assets/product-icons/DFFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "DFFB",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.dffb.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "DFFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YDFFB",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "DFFBSL": {
        "icon": "assets/product-icons/DFFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "DFFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.dffbsl.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "DFFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YDFFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HAFB": {
        "icon": "assets/product-icons/HAFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HAFB",
        "hasAssets": true,
        "modelId": "HAFB",
        "textureId": "HAFB",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collar",
                    "placketRight",
                    "placketLeft"
                ],
                "area3": [
                    "*.hafb.color3"
                ],
                "area4": [
                    "*.hafb.color4"
                ],
                "area5": [
                    "*.hafb.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HAFB",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HAFBSL": {
        "icon": "assets/product-icons/HAFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HAFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.hafbsl.color3"
                ],
                "area4": [
                    "*.hafbsl.color4"
                ],
                "area5": [
                    "*.hafbsl.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HAFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHAFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HOUSFB": {
        "icon": "assets/product-icons/HOUSFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HOUSFB",
        "hasAssets": true,
        "modelId": "CS10C",
        "textureId": "CS10C",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.housfb.color1"
                ],
                "area2": [
                    "*.housfb.color2"
                ],
                "area3": [
                    "*.housfb.color3"
                ],
                "area4": [
                    "*.housfb.color4"
                ],
                "area5": [
                    "*.housfb.color5"
                ],
                "area6": [
                    "*.housfb.color6"
                ],
                "area7": [
                    "*.housfb.color7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripe",
                "area4": "Sleeve End and Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HOUSFB",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HOUSFBSL": {
        "icon": "assets/product-icons/HOUSFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HOUSFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.housfbsl.color1"
                ],
                "area2": [
                    "*.housfbsl.color2"
                ],
                "area3": [
                    "*.housfbsl.color3"
                ],
                "area4": [
                    "*.housfbsl.color4"
                ],
                "area5": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area6": [
                    "*.housfbsl.color6"
                ],
                "area7": [
                    "*.housfbsl.color7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripes",
                "area4": "Middle stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HOUSFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHOUSFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HRDFB": {
        "icon": "assets/product-icons/HRDFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HRDFB",
        "hasAssets": true,
        "modelId": "CS10C",
        "textureId": "CS10C",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back"
                ],
                "area3": [
                    "*.hrdfb.color3"
                ],
                "area4": [
                    "*.hrdfb.color4"
                ],
                "area5": [
                    "*.ribKnitPlacket"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "jerseyBodyNoFade",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve",
                "area4": "Side inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HRDFB",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HRDFBSL": {
        "icon": "assets/product-icons/HRDFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HRDFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.hrdfbsl.color3"
                ],
                "area4": [
                    "*.hrdfbsl.color4"
                ],
                "area5": [
                    "*.ribKnitPlacket"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "standard",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HRDFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHRDFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "KUJ": {
        "icon": "assets/product-icons/KUJ.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": true
        },
        "code": "KUJ",
        "hasAssets": true,
        "modelId": "HOUSJ",
        "textureId": "HOUSJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.kuj.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.kuj.color3"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "noFade",
                "area4": "pattern",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Shoulder and Under Sleeve Inserts",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "KUJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "KLBPJVF": {
        "icon": "assets/product-icons/KLBPJVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "KLBPJVF",
        "hasAssets": true,
        "modelId": "KLBPJ",
        "textureId": "KLBPJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontLeft",
                    "frontRight",
                    "placketRight",
                    "placketLeft",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "neckInsert",
                    "collar",
                    "*.cuff"
                ],
                "area4": [
                    "frontInsertLeft",
                    "frontInsertRight",
                    "backInsertLeft",
                    "backInsertRight",
                    "sleeveInsert1",
                    "sleeveInsert2",
                    "sleeveInsert3",
                    "sleeveInsert4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": "standard",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck Insert & Sleeve End",
                "area4": "Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "KLBPJVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "MBFB": {
        "icon": "assets/product-icons/MBFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "MBFB",
        "hasAssets": true,
        "modelId": "CS10C",
        "textureId": "CS10C",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.mbfb.color1"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "placketRight",
                    "placketLeft"
                ],
                "area3": [
                    "*.mbfb.color3"
                ],
                "area4": [
                    "*.mbfb.color4"
                ],
                "area5": [
                    "*.mbfb.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeve insert",
                "area4": "Side inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MBFB",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "MBFBSL": {
        "icon": "assets/product-icons/MBFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "MBFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.mbfbsl.color1"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [],
                "area4": [
                    "*.mbfbsl.color4"
                ],
                "area5": [
                    "*.mbfbsl.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": null,
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MBFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YMBFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "ROAKFB": {
        "icon": "assets/product-icons/ROAKFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ROAKFB",
        "hasAssets": true,
        "modelId": "CS10C",
        "textureId": "CS10C",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collar",
                    "placketRight",
                    "placketLeft"
                ],
                "area3": [
                    "*.roakfb.color3"
                ],
                "area4": [
                    "*.roakfb.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Sleeve Outer",
                "area4": "Neck/Front Middle & Sleeve Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ROAKFB",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "ROAKFBSL": {
        "icon": "assets/product-icons/ROAKFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ROAKFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.roakfbsl.color3",
                    "collar"
                ],
                "area4": [
                    "*.roakfbsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Armhole Outer",
                "area4": "Neck/Front Middle & Armhole Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ROAKFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YROAKFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WINGFB": {
        "icon": "assets/product-icons/WINGFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WINGFB",
        "hasAssets": true,
        "modelId": "CSSF",
        "textureId": "CSSF",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wingfb.color1"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.wingfb.color4"
                ],
                "area5": [
                    "*.wingfb.color5"
                ],
                "area6": [
                    "*.wingfb.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Sleeve Piping",
                "area2": "Body",
                "area3": "Lower Sleeve",
                "area4": "Upper Sleeve",
                "area5": "Side Insert",
                "area6": "Front Placket",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WINGFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YWINGFB",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WINGFBSL": {
        "icon": "assets/product-icons/WINGFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WINGFBSL",
        "hasAssets": true,
        "modelId": "MFBSL",
        "textureId": "MFBSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wingfbsl.color1"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketBack"
                ],
                "area3": [
                    "*.wingfbsl.color3"
                ],
                "area4": [
                    "*.wingfbsl.color4"
                ],
                "area5": [
                    "*.wingfbsl.color5"
                ],
                "area6": [
                    "*.wingfbsl.color6",
                    "placketLeft",
                    "placketRight"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Shoulder Piping",
                "area2": "Body",
                "area3": "Armhole Insert",
                "area4": "Shoulder and Upper Collar",
                "area5": "Side Insert",
                "area6": "Front Facing",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WINGFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YWINGFBSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "ARZ2B": {
        "icon": "assets/product-icons/ARZ2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ARZ2B",
        "hasAssets": true,
        "modelId": "ARZ2B",
        "textureId": "ARZ2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collar",
                    "collarInside",
                    "placketLeft",
                    "placketRight",
                    "placketLeftInside",
                    "placketRightInside"
                ],
                "area3": [
                    "*.arz2b.color3"
                ],
                "area4": [
                    "*.arz2b.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Sleeve Accents and Across The Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ARZ2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "ARZ2BSL": {
        "icon": "assets/product-icons/ARZ2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ARZ2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.arz2bsl.color3"
                ],
                "area4": [
                    "*.arz2bsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Shoulder Accents and Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ARZ2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YARZ2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BB2B": {
        "icon": "assets/product-icons/BB2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BB2B",
        "hasAssets": true,
        "modelId": "ARZ2B",
        "textureId": "ARZ2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.bb2b.color3"
                ],
                "area4": [
                    "*.bb2b.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body / Sleeve Border",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BB2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YBB2B",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BB2BSL": {
        "icon": "assets/product-icons/BB2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BB2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.bb2bsl.color3"
                ],
                "area4": [
                    "*.bb2bsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BB2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YBB2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CHI2B": {
        "icon": "assets/product-icons/CHI2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CHI2B",
        "hasAssets": true,
        "modelId": "HRD2B",
        "textureId": "HRD2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.chi2b.color3"
                ],
                "area4": [
                    "*.chi2b.color4"
                ],
                "area5": [
                    "*.chi2b.color5"
                ],
                "area6": [
                    "*.chi2b.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripes",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CHI2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CHI2BSL": {
        "icon": "assets/product-icons/CHI2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CHI2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.chi2bsl.color3"
                ],
                "area4": [
                    "*.chi2bsl.color4"
                ],
                "area5": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area6": [
                    "*.chi2bsl.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Striptes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CHI2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YCHI2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CJP2VF": {
        "icon": "assets/product-icons/CJP2VF.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CJP2VF",
        "hasAssets": true,
        "modelId": "CJP2",
        "textureId": "CJP2",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collarBack",
                    "collarFrontAbove",
                    "collarFrontBelow"
                ],
                "area3": [
                    "*.insert"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": "Inserts",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJP2VF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CJ2BIVF": {
        "icon": "assets/product-icons/CJ2BIVF.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "ribKnitCollar",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CJ2BIVF",
        "hasAssets": true,
        "modelId": "CJ2BI",
        "textureId": "CJ2BI",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [
                    "placketFront",
                    "placketBack"
                ],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLeft"
                ],
                "area5": [
                    "sideInsert1",
                    "sideInsert2",
                    "sideInsert3",
                    "sideInsert4"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": "standard",
                "area5": "jerseyBody",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Right Sleeve",
                "area4": "Left Sleeve",
                "area5": "Side Inserts",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": true,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CJ2BIVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "DF2B": {
        "icon": "assets/product-icons/DF2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "DF2B",
        "hasAssets": true,
        "modelId": "ARZ2B",
        "textureId": "ARZ2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.df2b.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "DF2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YDF2B",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "DF2BSL": {
        "icon": "assets/product-icons/DF2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "DF2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.df2bsl.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "DF2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YDF2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HA2B": {
        "icon": "assets/product-icons/HA2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HA2B",
        "hasAssets": true,
        "modelId": "CJP2",
        "textureId": "CJP2",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collarBack",
                    "collarFrontAbove",
                    "collarFrontBelow"
                ],
                "area3": [
                    "*.ha2b.color3"
                ],
                "area4": [
                    "*.ha2b.color4"
                ],
                "area5": [
                    "*.ha2b.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HA2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HA2BSL": {
        "icon": "assets/product-icons/HA2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HA2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.ha2bsl.color3"
                ],
                "area4": [
                    "*.ha2bsl.color4"
                ],
                "area5": [
                    "*.ha2bsl.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HA2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHA2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HOUS2B": {
        "icon": "assets/product-icons/HOUS2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HOUS2B",
        "hasAssets": true,
        "modelId": "HRD2B",
        "textureId": "HRD2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.hous2b.color1"
                ],
                "area2": [
                    "*.hous2b.color2"
                ],
                "area3": [
                    "*.hous2b.color3"
                ],
                "area4": [
                    "*.hous2b.color4"
                ],
                "area5": [
                    "*.hous2b.color5"
                ],
                "area6": [
                    "*.hous2b.color6"
                ],
                "area7": [
                    "*.hous2b.color7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripe",
                "area4": "Sleeve End and Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HOUS2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HOUS2BSL": {
        "icon": "assets/product-icons/HOUS2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HOUS2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.hous2bsl.color1"
                ],
                "area2": [
                    "*.hous2bsl.color2"
                ],
                "area3": [
                    "*.hous2bsl.color3"
                ],
                "area4": [
                    "*.hous2bsl.color4"
                ],
                "area5": [
                    "front",
                    "back"
                ],
                "area6": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area7": [
                    "*.hous2bsl.color7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripes",
                "area4": "Middle stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HOUS2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHOUS2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HRD2B": {
        "icon": "assets/product-icons/HRD2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HRD2B",
        "hasAssets": true,
        "modelId": "HRD2B",
        "textureId": "HRD2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.hrd2b.color3"
                ],
                "area4": [
                    "*.hrd2b.color4"
                ],
                "area5": [
                    "collar",
                    "collarInside",
                    "placketLeft",
                    "placketRight",
                    "placketLeftInside",
                    "placketRightInside"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "jerseyBodyNoFade",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve",
                "area4": "Side inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HRD2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HRD2BSL": {
        "icon": "assets/product-icons/HRD2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HRD2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.hrd2bsl.color3"
                ],
                "area4": [
                    "*.hrd2bsl.color4"
                ],
                "area5": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside",
                    "*.ribKnitCollar"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "standard",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HRD2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHRD2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "MB2B": {
        "icon": "assets/product-icons/MB2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "MB2B",
        "hasAssets": true,
        "modelId": "HRD2B",
        "textureId": "HRD2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.mb2b.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "placketRight",
                    "placketLeft"
                ],
                "area3": [
                    "*.mb2b.color3"
                ],
                "area4": [
                    "*.mb2b.color4"
                ],
                "area5": [
                    "*.mb2b.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeve insert",
                "area4": "Side inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MB2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "MB2BSL": {
        "icon": "assets/product-icons/MB2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "MB2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [],
                "area4": [
                    "*.mb2bsl.color4"
                ],
                "area5": [
                    "*.mb2bsl.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": null,
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": null,
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MB2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YMB2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "ROAK2B": {
        "icon": "assets/product-icons/ROAK2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ROAK2B",
        "hasAssets": true,
        "modelId": "HRD2B",
        "textureId": "HRD2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collar",
                    "placketRight",
                    "placketLeft"
                ],
                "area3": [
                    "*.roak2b.color3"
                ],
                "area4": [
                    "*.roak2b.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck Outer & Sleeve Outer",
                "area4": "Neck Middle & Sleeve Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ROAK2B",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "ROAK2BSL": {
        "icon": "assets/product-icons/ROAK2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ROAK2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.roak2bsl.color3",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area4": [
                    "*.roak2bsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Armhole Outer",
                "area4": "Neck/Front Middle & Armhole Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ROAK2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YROAK2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WING2B": {
        "icon": "assets/product-icons/WING2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WING2B",
        "hasAssets": true,
        "modelId": "ARZ2B",
        "textureId": "ARZ2B",
        "asset": {
            "isSleeveless": false,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wing2b.color1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.wing2b.color4"
                ],
                "area5": [
                    "*.wing2b.color5"
                ],
                "area6": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Sleeve Piping",
                "area2": "Body",
                "area3": "Lower Sleeve",
                "area4": "Upper Sleeve",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WING2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YWING2B",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WING2BSL": {
        "icon": "assets/product-icons/WING2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WING2BSL",
        "hasAssets": true,
        "modelId": "M2BSL",
        "textureId": "M2BSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": true,
            "placketType": "double"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wing2bsl.color1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.wing2bsl.color3"
                ],
                "area4": [
                    "*.wing2bsl.color4"
                ],
                "area5": [
                    "*.wing2bsl.color5"
                ],
                "area6": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Shoulder Piping",
                "area2": "Body",
                "area3": "Armhole Insert",
                "area4": "Shoulder",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WING2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YWING2BSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "ARZVN": {
        "icon": "assets/product-icons/ARZVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ARZVN",
        "hasAssets": true,
        "modelId": "HAVN",
        "textureId": "HAVN",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collar"
                ],
                "area3": [
                    "*.arzvn.color3"
                ],
                "area4": [
                    "*.arzvn.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Sleeve Accents and Across The Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ARZVN",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "ARZVNSL": {
        "icon": "assets/product-icons/ARZVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ARZVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "*.arzvnsl.color3"
                ],
                "area4": [
                    "*.arzvnsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Shoulder Accents and Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ARZVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YARZVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BBVN": {
        "icon": "assets/product-icons/BBVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BBVN",
        "hasAssets": true,
        "modelId": "HAVN",
        "textureId": "HAVN",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.bbvn.color3"
                ],
                "area4": [
                    "*.bbvn.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body / Sleeve Border",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BBVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YBBVN",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BBVNSL": {
        "icon": "assets/product-icons/BBVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BBVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "BBVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "*.bbvnsl.color3"
                ],
                "area4": [
                    "*.bbvnsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BBVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YBBVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CHIJ": {
        "icon": "assets/product-icons/CHIJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CHIJ",
        "hasAssets": true,
        "modelId": "HOUSJ",
        "textureId": "CHIJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "front.middleStripe",
                    "sleeveLeft.middleStripe",
                    "sleeveRight.middleStripe"
                ],
                "area4": [
                    "front.borderStripe",
                    "sleeveLeft.borderStripe",
                    "sleeveRight.borderStripe"
                ],
                "area5": [
                    "collar.upperCollar"
                ],
                "area6": [
                    "collar.lowerCollar"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripes",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CHIJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CHIVNSL": {
        "icon": "assets/product-icons/CHIVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CHIVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "CHIVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.chivnsl.color3"
                ],
                "area4": [
                    "*.chivnsl.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [
                    "*.chivnsl.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Striptes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CHIVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YCHIVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CLNCHJ": {
        "icon": "assets/product-icons/CLNCHJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CLNCHJ",
        "hasAssets": true,
        "modelId": "CLNCHJ",
        "textureId": "CLNCHJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.clnchj.color3"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "noFade",
                "area4": "pattern",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve Inserts",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CLNCHJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "CS2VF": {
        "icon": "assets/product-icons/CS2VF.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "sublimatedCollar",
            "sublimatedCuff",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CS2VF",
        "hasAssets": true,
        "modelId": "CS2-without-cuff",
        "textureId": "CS2-without-cuff",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "front",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body and Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": true,
            "sublimatedCuff": true,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CS2VF",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YCS2VF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "DFVN": {
        "icon": "assets/product-icons/DFVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "DFVN",
        "hasAssets": true,
        "modelId": "HAVN",
        "textureId": "HAVN",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": "fake"
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.dfvn.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "DFVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YDFVN",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "DFVNSL": {
        "icon": "assets/product-icons/DFVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "DFVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "*.dfvnsl.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "DFVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YDFVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HAVN": {
        "icon": "assets/product-icons/HAVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HAVN",
        "hasAssets": true,
        "modelId": "HAVN",
        "textureId": "HAVN",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collar"
                ],
                "area3": [
                    "*.havn.color3"
                ],
                "area4": [
                    "*.havn.color4"
                ],
                "area5": [
                    "*.havn.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "pattern",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body/Top of Sleeves",
                "area3": "Under Sleeve/Top of Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": true
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HAVN",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HAVNSL": {
        "icon": "assets/product-icons/HAVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HAVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.havnsl.color3"
                ],
                "area4": [
                    "*.havnsl.color4"
                ],
                "area5": [
                    "*.havnsl.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HAVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHAVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HOUSJ": {
        "icon": "assets/product-icons/HOUSJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HOUSJ",
        "hasAssets": true,
        "modelId": "HOUSJ",
        "textureId": "HOUSJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "front.seam",
                    "back.seam",
                    "*.col1"
                ],
                "area2": [
                    "*.col2"
                ],
                "area3": [
                    "*.col3"
                ],
                "area4": [
                    "sleeveLeft.seam",
                    "sleeveRight.seam",
                    "*.col4"
                ],
                "area5": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area6": [
                    "*.col6"
                ],
                "area7": [
                    "*.col7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripe",
                "area4": "Sleeve End and Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": [
                "watermarkText",
                "watermarkLogo.stock",
                "watermarkLogo.custom"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HOUSJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HOUSVNSL": {
        "icon": "assets/product-icons/HOUSVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HOUSVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.housvnsl.color1"
                ],
                "area2": [
                    "*.housvnsl.color2"
                ],
                "area3": [
                    "*.housvnsl.color3"
                ],
                "area4": [
                    "*.housvnsl.color4"
                ],
                "area5": [
                    "front",
                    "back"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [
                    "*.housvnsl.color7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripes",
                "area4": "Middle stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HOUSVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHOUSVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "HRDJ": {
        "icon": "assets/product-icons/HRDJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HRDJ",
        "hasAssets": true,
        "modelId": "HOUSJ",
        "textureId": "CHIJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.hrdj.color3",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.hrdj.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "jerseyBodyNoFade",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve",
                "area4": "Side inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HRDJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "HRDVNSL": {
        "icon": "assets/product-icons/HRDVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "HRDVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.hrdvnsl.color3"
                ],
                "area4": [
                    "*.hrdvnsl.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "standard",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "HRDVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YHRDVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "MBVN": {
        "icon": "assets/product-icons/MBVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "MBVN",
        "hasAssets": true,
        "modelId": "HOUSJ",
        "textureId": "CHIJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.mbvn.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.mbvn.color3"
                ],
                "area4": [
                    "*.mbvn.color4"
                ],
                "area5": [
                    "*.mbvn.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeve Insert",
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": "m",
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MBVN",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "MBVNSL": {
        "icon": "assets/product-icons/MBVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "MBVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [],
                "area4": [
                    "*.mbvnsl.color4"
                ],
                "area5": [
                    "*.mbvnsl.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": null,
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MBVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YMBVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "NYMJ": {
        "icon": "assets/product-icons/NYMJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "NYMJ",
        "hasAssets": true,
        "modelId": "CS2-without-cuff",
        "textureId": "CS2-without-cuff",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.nymj.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.nymj.color3"
                ],
                "area4": [
                    "*.nymj.color4"
                ],
                "area5": [
                    "*.nymj.color5"
                ],
                "area6": [
                    "*.nymj.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Inside Shoulder Stripe",
                "area4": "Outside Shoulder Stripe",
                "area5": "Inside Insert Stripe",
                "area6": "Outside Insert Stripe",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "NYMJ",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "RJOAK": {
        "icon": "assets/product-icons/RJOAK.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "RJOAK",
        "hasAssets": true,
        "modelId": "HOUSJ",
        "textureId": "HOUSJ",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "sleeveLeft.borderStripe",
                    "sleeveRight.borderStripe",
                    "collar.lowerCollar"
                ],
                "area4": [
                    "sleeveLeft.middleStripe",
                    "sleeveRight.middleStripe",
                    "collar.upperCollar"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck Outer & Sleeve Outer",
                "area4": "Neck Middle & Sleeve Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RJOAK",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "ROAKVNSL": {
        "icon": "assets/product-icons/ROAKVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "ROAKVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.roakvnsl.color3",
                    "collar"
                ],
                "area4": [
                    "*.roakvnsl.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Armhole Outer",
                "area4": "Neck/Front Middle & Armhole Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "ROAKVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YROAKVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WINGVN": {
        "icon": "assets/product-icons/WINGVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WINGVN",
        "hasAssets": true,
        "modelId": "HAVN",
        "textureId": "HAVN",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wingvn.color1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.wingvn.color4"
                ],
                "area5": [
                    "*.wingvn.color5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Sleeve Piping",
                "area2": "Body",
                "area3": "Lower Sleeve",
                "area4": "Upper Sleeve",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WINGVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YWINGVN",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WINGVNSL": {
        "icon": "assets/product-icons/WINGVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WINGVNSL",
        "hasAssets": true,
        "modelId": "MVNSL",
        "textureId": "MVNSL",
        "asset": {
            "isSleeveless": true,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wingvnsl.color1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.wingvnsl.color3"
                ],
                "area4": [
                    "*.wingvnsl.color4"
                ],
                "area5": [
                    "*.wingvnsl.color5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Shoulder Piping",
                "area2": "Body",
                "area3": "Armhole Insert",
                "area4": "Shoulder",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WINGVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YWINGVNSL",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "FTVN1": {
        "icon": "assets/product-icons/FTVN1.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTVN1",
        "hasAssets": true,
        "modelId": "FTVN1",
        "textureId": "FTVN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftvn1.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftvn1.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Lower Sleeve / Bottom Neck",
                "area2": "Body / Upper neck",
                "area3": "Upper sleeve stripe",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTVN1",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTVN2": {
        "icon": "assets/product-icons/FTVN2.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTVN2",
        "hasAssets": true,
        "modelId": "FTVN1",
        "textureId": "FTVN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTVN2",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTVN3": {
        "icon": "assets/product-icons/FTVN3.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTVN3",
        "hasAssets": true,
        "modelId": "FTVN1",
        "textureId": "FTVN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftvn3.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftvn3.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Sleeve End/Outline/Neck Stripe",
                "area2": "Body/Sleeve/Top of Neck",
                "area3": "Side Insert",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTVN3",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTVN4": {
        "icon": "assets/product-icons/FTVN4.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTVN4",
        "hasAssets": true,
        "modelId": "FTVN1",
        "textureId": "FTVN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.ftvn4.color3"
                ],
                "area4": [
                    "*.ftvn4.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Outside Insert",
                "area4": "Middle Insert",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTVN4",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTVN5": {
        "icon": "assets/product-icons/FTVN5.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTVN5",
        "hasAssets": true,
        "modelId": "FTVN1",
        "textureId": "FTVN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.ftvn5.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Neck",
                "area2": "Body",
                "area3": "Fade Design Sleeves",
                "area4": "Fade Design Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTVN5",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTVN6": {
        "icon": "assets/product-icons/FTVN6.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTVN6",
        "hasAssets": true,
        "modelId": "FTVN1",
        "textureId": "FTVN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTVN6",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTVN7": {
        "icon": "assets/product-icons/FTVN7.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTVN7",
        "hasAssets": true,
        "modelId": "FTVN1",
        "textureId": "FTVN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTVN7",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTCN1": {
        "icon": "assets/product-icons/FTCN1.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTCN1",
        "hasAssets": true,
        "modelId": "FTCN1",
        "textureId": "FTCN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftcn1.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftcn1.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Lower Sleeve / Bottom Neck",
                "area2": "Body / Upper neck",
                "area3": "Upper sleeve stripe",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTCN1",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTCN2": {
        "icon": "assets/product-icons/FTCN2.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTCN2",
        "hasAssets": true,
        "modelId": "FTCN1",
        "textureId": "FTCN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTCN2",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTCN3": {
        "icon": "assets/product-icons/FTCN3.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTCN3",
        "hasAssets": true,
        "modelId": "FTCN1",
        "textureId": "FTCN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftcn3.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftcn3.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Sleeve End/Outline/Neck Stripe",
                "area2": "Body/Sleeve/Top of Neck",
                "area3": "Side Insert",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTCN3",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTCN4": {
        "icon": "assets/product-icons/FTCN4.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTCN4",
        "hasAssets": true,
        "modelId": "FTCN1",
        "textureId": "FTCN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.ftcn4.color3"
                ],
                "area4": [
                    "*.ftcn4.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Outside Insert",
                "area4": "Middle Insert",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTCN4",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTCN5": {
        "icon": "assets/product-icons/FTCN5.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTCN5",
        "hasAssets": true,
        "modelId": "FTCN1",
        "textureId": "FTCN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.ftcn5.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Neck",
                "area2": "Body",
                "area3": "Fade Design Sleeves",
                "area4": "Fade Design Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTCN5",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTCN6": {
        "icon": "assets/product-icons/FTCN6.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTCN6",
        "hasAssets": true,
        "modelId": "FTCN1",
        "textureId": "FTCN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTCN6",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTCN7": {
        "icon": "assets/product-icons/FTCN7.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTCN7",
        "hasAssets": true,
        "modelId": "FTCN1",
        "textureId": "FTCN1",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": false,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTCN7",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "CSRMTVF": {
        "icon": "assets/product-icons/CSRMTVF.jpg?2014-09-30",
        "category": "sublimatedJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CSRMTVF",
        "hasAssets": true,
        "modelId": "CSRMT",
        "textureId": "CSRMT",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "jerseyBody",
                "area4": "jerseyBody",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Neck",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CSRMTVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "S9BTVF": {
        "icon": "assets/product-icons/S9BTVF.jpg?2014-09-30",
        "category": "sublimatedJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "S9BTVF",
        "hasAssets": true,
        "modelId": "S9BT",
        "textureId": "S9BT",
        "asset": {
            "isSleeveless": false,
            "hasButtons": false,
            "placketType": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "shoulderInsertLeft",
                    "shoulderInsertRight",
                    "sleeveInsertLeft",
                    "sleeveInsertRight"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve Inserts",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP2_5": false,
            "OP3": true,
            "ribKnitCollar": false,
            "ribKnitCuff": false,
            "sublimatedCollar": false,
            "sublimatedCuff": false,
            "logoColor": "SBPT",
            "buttonColor": false
        },
        "decoration": {
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "teamnameAlign": null,
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "S9BTVF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "CPVN": {
        "icon": "assets/product-icons/CPVN.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP4B",
            "OP4C",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CPVN",
        "hasAssets": true,
        "modelId": "CPVN",
        "textureId": "CPVN",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE",
                    "insert"
                ],
                "area3": [
                    "*.insert"
                ],
                "area4": [
                    "*.pocket.pocketBelow",
                    "*.pocket.pocketAbove"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "V-Insert",
                "area4": "Pocket Welts",
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": true,
            "OP4C": true,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": null
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CPK": {
        "icon": "assets/product-icons/CPK.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CPK",
        "hasAssets": true,
        "modelId": "CPK",
        "textureId": "CPK",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPK",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CP5": {
        "icon": "assets/product-icons/CP5.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CP5",
        "hasAssets": true,
        "modelId": "CP5",
        "textureId": "CP5",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CP5",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CPRO": {
        "icon": "assets/product-icons/CPRO.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP15",
            "RIBWST",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CPRO",
        "hasAssets": true,
        "modelId": "CP5MR",
        "textureId": "CP5MR",
        "asset": {
            "hasFakeInside": false
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": true,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPRO",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CP5MR": {
        "icon": "assets/product-icons/CP5MR.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CP5MR",
        "hasAssets": true,
        "modelId": "CP5MR",
        "textureId": "CP5MR",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CP5MR",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Relaxed Fit Pant"
    },
    "CPW": {
        "icon": "assets/product-icons/CPW.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP15",
            "RIBWST",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CPW",
        "hasAssets": true,
        "modelId": "CPW",
        "textureId": "CPW",
        "asset": {
            "hasFakeInside": false
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": true,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPW",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CPROB": {
        "icon": "assets/product-icons/CPROB.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CPROB",
        "hasAssets": true,
        "modelId": "CPROB",
        "textureId": "CPROB",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [
                    "*.op_back_pocket.area_B",
                    "*.op_loop.area_B"
                ],
                "area4": [
                    "*.op_back_pocket.area_C",
                    "*.op_loop.area_C"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": "Front Stripe",
                "area4": "Back Stripe",
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPROB",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CPVP2": {
        "icon": "assets/product-icons/CPVP2.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP4B",
            "OP4C",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CPVP2",
        "hasAssets": true,
        "modelId": "CPVP2",
        "textureId": "CPVP2",
        "asset": {
            "hasFakeInside": false
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE",
                    "waistBack"
                ],
                "area3": [
                    "insertBack"
                ],
                "area4": [
                    "insertBackLeft",
                    "insertBackRight"
                ],
                "area5": [
                    "*.pocket.pocketBelow",
                    "*.pocket.pocketAbove"
                ]
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors"
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Upper Insert",
                "area4": "Lower Inserts",
                "area5": "Pocket Welts"
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": true,
            "OP4C": true,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPVP2",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "KLPHSO": {
        "icon": "assets/product-icons/KLPHSO.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "KLPHSO",
        "hasAssets": true,
        "modelId": "CP5MR",
        "textureId": "CP5MR",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customPant"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "KLPHSO",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "PPU140": {
        "icon": "assets/product-icons/PPU140.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "PPU140",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "PPU140"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPU140",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "PPU140A": {
        "icon": "assets/product-icons/PPU140A.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "PPU140A",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "PPU140A"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPU140A",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "PRO150": {
        "icon": "assets/product-icons/PRO150.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "PRO150",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "PRO150"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PRO150",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "PRO150P": {
        "icon": "assets/product-icons/PRO150P.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "PRO150P",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [
                    "BASE"
                ],
                "area2-1": [
                    "*.piping"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "PRO150P"
            ]
        },
        "options": {
            "OP4": false,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PRO150P",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "LNCHSRP": {
        "icon": "assets/product-icons/LNCHSRP.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "LNCHSRP",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [
                    "BASE"
                ],
                "area2-1": [
                    "*.piping"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "LNCHSRP"
            ]
        },
        "options": {
            "OP4": false,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "LNCHSRP",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "LNCHSR": {
        "icon": "assets/product-icons/LNCHSR.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "LNCHSR",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "LNCHSR"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "LNCHSR",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BPU150": {
        "icon": "assets/product-icons/BPU150.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BPU150",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BPU150"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BPU150",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BP150K": {
        "icon": "assets/product-icons/BP150K.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "BP150K",
        "hasAssets": true,
        "modelId": "CPK",
        "textureId": "CPK",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BP150K"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BP150K",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "LNCHKP": {
        "icon": "assets/product-icons/LNCHKP.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "LNCHKP",
        "hasAssets": true,
        "modelId": "CPK",
        "textureId": "CPK",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "LNCHKP"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "LNCHKP",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "LNCHKPP": {
        "icon": "assets/product-icons/LNCHKPP.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "LNCHKPP",
        "hasAssets": true,
        "modelId": "CPK",
        "textureId": "CPK",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [
                    "BASE"
                ],
                "area2-1": [
                    "*.piping"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "LNCHKPP"
            ]
        },
        "options": {
            "OP4": false,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "LNCHKPP",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BP95MR": {
        "icon": "assets/product-icons/BP95MR.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "BP95MR",
        "hasAssets": false,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": true,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BP95MR"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BP95MR",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "PP350MR": {
        "icon": "assets/product-icons/PP350MR.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "PP350MR",
        "hasAssets": false,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "PP350MR"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PP350MR",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BPVP": {
        "icon": "assets/product-icons/BPVP.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4",
            "OP4B",
            "OP4C"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "BPVP",
        "hasAssets": false,
        "modelId": "CPVN",
        "textureId": "CPVN",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [
                    "BASE"
                ],
                "area2-1": [
                    "???"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BPVP"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": true,
            "OP4C": true,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BPVP",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BP31MR": {
        "icon": "assets/product-icons/BP31MR.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "BP31MR",
        "hasAssets": false,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BP31MR"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BP31MR",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BEP31": {
        "icon": "assets/product-icons/BEP31.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "BEP31",
        "hasAssets": true,
        "modelId": "BEP31",
        "textureId": "BEP31",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BEP31"
            ]
        },
        "options": {
            "OP4": false,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BEP31",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BP31SR": {
        "icon": "assets/product-icons/BP31SR.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "BP31SR",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BP31SR"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BP31SR",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "PIN150": {
        "icon": "assets/product-icons/PIN150.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "PIN150",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": true,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "PIN150"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PIN150",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "BPVP2": {
        "icon": "assets/product-icons/BPVP2.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4",
            "OP4B",
            "OP4C"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "BPVP2",
        "hasAssets": true,
        "modelId": "CPVP2",
        "textureId": "CPVP2",
        "asset": {
            "hasFakeInside": false
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [
                    "frontRight",
                    "frontLeft",
                    "backLeft",
                    "backRight",
                    "waistBack",
                    "crotch",
                    "insertBackLeft",
                    "insertBackRight",
                    "loopSmall",
                    "loopSide",
                    "loopBack",
                    "button.fill"
                ],
                "area2-1": [
                    "insertBack",
                    "*.pocket.pocketBelow",
                    "*.pocket.pocketAbove"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BPVP2"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": true,
            "OP4C": true,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BPVP2",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "BP150": {
        "icon": "assets/product-icons/BP150.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "BP150",
        "hasAssets": true,
        "modelId": "PRO150",
        "textureId": "PRO150",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "BP150"
            ]
        },
        "options": {
            "OP4": false,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "BP150",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "RP150": {
        "icon": "assets/product-icons/RP150.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "RP150",
        "hasAssets": true,
        "modelId": "CPROB",
        "textureId": "CPROB",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [
                    "loopSideLeft",
                    "loopSideRight",
                    "loopBack",
                    "loopSmall",
                    "crotch",
                    "backRight",
                    "backLeft",
                    "underfly",
                    "frontRight",
                    "frontLeft",
                    "button.fill"
                ],
                "area2-1": [
                    "*.op4.area_C",
                    "*.op_belt_loop_side.area_C",
                    "*.op_belt_loop_back.area_C",
                    "*.op_loop.area_C"
                ],
                "area2-2": [
                    "*.op4.area_B",
                    "*.op_belt_loop_side.area_B",
                    "*.op_belt_loop_back.area_B",
                    "*.op_loop.area_B"
                ],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "RP150"
            ]
        },
        "options": {
            "OP4": false,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "sbpt"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "RP150",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CP5VF": {
        "icon": "assets/product-icons/CP5VF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CP5VF",
        "hasAssets": true,
        "modelId": "CP5",
        "textureId": "CP5",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CP5VF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CP5MRVF": {
        "icon": "assets/product-icons/CP5MRVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CP5MRVF",
        "hasAssets": true,
        "modelId": "CP5MR",
        "textureId": "CP5MR",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CP5MRVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Relaxed Fit Pant"
    },
    "CPVNVF": {
        "icon": "assets/product-icons/CPVNVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "OP4",
            "OP4B",
            "OP4C",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CPVNVF",
        "hasAssets": true,
        "modelId": "CPVN",
        "textureId": "CPVN",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [
                    "*.insert"
                ],
                "area4": [
                    "*.pocket.pocketBelow",
                    "*.pocket.pocketAbove"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": "standard",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Inserts",
                "area4": "Pocket Welts",
                "area5": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": true,
            "OP4C": true,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPVNVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CPROVF": {
        "icon": "assets/product-icons/CPROVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "OP4",
            "OP15",
            "RIBWST",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CPROVF",
        "hasAssets": true,
        "modelId": "CP5MR",
        "textureId": "CP5MR",
        "asset": {
            "hasFakeInside": false
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": true,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPROVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CPKVF": {
        "icon": "assets/product-icons/CPKVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CPKVF",
        "hasAssets": true,
        "modelId": "CPK",
        "textureId": "CPK",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPKVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CPVP2VF": {
        "icon": "assets/product-icons/CPVP2VF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "OP4",
            "OP4B",
            "OP4C",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CPVP2VF",
        "hasAssets": true,
        "modelId": "CPVP2",
        "textureId": "CPVP2",
        "asset": {
            "hasFakeInside": true
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE",
                    "waistBack"
                ],
                "area3": [
                    "insertBack"
                ],
                "area4": [
                    "insertBackLeft",
                    "insertBackRight"
                ],
                "area5": [
                    "*.pocket.pocketBelow",
                    "*.pocket.pocketAbove"
                ]
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": "standard",
                "area4": "standard",
                "area5": "solidColors"
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Upper Insert",
                "area4": "Lower Inserts",
                "area5": "Pocket Welts"
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": true,
            "OP4C": true,
            "OP15": true,
            "RIBWST": false,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPVP2VF",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/18"
    },
    "CPROIVF": {
        "icon": "assets/product-icons/CPROIVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CPROIVF",
        "hasAssets": true,
        "modelId": "CPROIVF",
        "textureId": "CPROIVF",
        "asset": {
            "hasFakeInside": false
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [
                    "insertRight",
                    "insertLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": "patternAndStandard",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": false,
            "OP4B": false,
            "OP4C": false,
            "OP15": false,
            "RIBWST": false,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPROIVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CPWVF": {
        "icon": "assets/product-icons/CPWVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "OP4",
            "OP15",
            "RIBWST",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CPWVF",
        "hasAssets": true,
        "modelId": "CPW",
        "textureId": "CPW",
        "asset": {
            "hasFakeInside": false
        },
        "colorAreas": {
            "autoPinstripes": false,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "BASE"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "pantBody",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "SBPT"
            ]
        },
        "options": {
            "OP4": true,
            "OP4B": false,
            "OP4C": false,
            "OP15": true,
            "RIBWST": true,
            "logoColor": "SBPT"
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CPWVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    }
}