module.exports={
    "toggles": {
        "all": [
            "on",
            "off"
        ],
        "off": [
            "off"
        ],
        "notAvailable": [
            "notAvailable"
        ]
    },
    "fonts": {
        "all": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock",
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "proBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "teamName": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "playerName": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "watermarkText": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "allText": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "fasttrackText": [
            "connecticut",
            "fullBlock",
            "method",
            "miami",
            "script",
            "monolith",
            "stinger",
            "rock"
        ],
        "number": [
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "proBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "allNumber": [
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "fasttrackNumber": [
            "connecticutNumber",
            "fullBlockNumber",
            "methodNumber",
            "miami47",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ]
    },
    "jerseys": {
        "all": [
            "CJSB",
            "CS2",
            "CJP2",
            "CSUCPJ",
            "CSSP",
            "CSMPPJ",
            "SS1",
            "CJDIP",
            "CROAD",
            "S9BT",
            "CSRMT",
            "CJ2BI",
            "CSPL",
            "CSSIFJ",
            "CSMPFJ",
            "CJ3F",
            "CJMSI",
            "CSBI",
            "CS5",
            "CS10C",
            "BBSS",
            "CJDI",
            "CSUCFJ",
            "CSSF",
            "CJI",
            "ADBPJ13",
            "KLBPJ",
            "ROAD",
            "RBJ2B",
            "SSBASE",
            "RJFADE",
            "RJ140",
            "RJ140A",
            "RBJ150",
            "RSJ150",
            "RBBJ95",
            "RBBJ350",
            "RBJ167",
            "HBJ",
            "RTT",
            "ADBPJ13VF",
            "ARZFBSL",
            "ARZJ",
            "BBFB",
            "BBFBSL",
            "BBSSVF",
            "CHIFB",
            "CHIFBSL",
            "CJDIVF",
            "CS10CVF",
            "CS5VF",
            "CSSFVF",
            "DFFB",
            "DFFBSL",
            "HAFB",
            "HAFBSL",
            "HOUSFB",
            "HOUSFBSL",
            "HRDFB",
            "HRDFBSL",
            "KUJ",
            "KLBPJVF",
            "MBFB",
            "MBFBSL",
            "ROAKFB",
            "ROAKFBSL",
            "WINGFB",
            "WINGFBSL",
            "ARZ2B",
            "ARZ2BSL",
            "BB2B",
            "BB2BSL",
            "CHI2B",
            "CHI2BSL",
            "CJP2VF",
            "CJ2BIVF",
            "DF2B",
            "DF2BSL",
            "HA2B",
            "HA2BSL",
            "HOUS2B",
            "HOUS2BSL",
            "HRD2B",
            "HRD2BSL",
            "MB2B",
            "MB2BSL",
            "ROAK2B",
            "ROAK2BSL",
            "WING2B",
            "WING2BSL",
            "ARZVN",
            "ARZVNSL",
            "BBVN",
            "BBVNSL",
            "CHIJ",
            "CHIVNSL",
            "CLNCHJ",
            "CS2VF",
            "DFVN",
            "DFVNSL",
            "HAVN",
            "HAVNSL",
            "HOUSJ",
            "HOUSVNSL",
            "HRDJ",
            "HRDVNSL",
            "MBVN",
            "MBVNSL",
            "NYMJ",
            "RJOAK",
            "ROAKVNSL",
            "WINGVN",
            "WINGVNSL",
            "FTVN1",
            "FTVN2",
            "FTVN3",
            "FTVN4",
            "FTVN5",
            "FTVN6",
            "FTVN7",
            "FTCN1",
            "FTCN2",
            "FTCN3",
            "FTCN4",
            "FTCN5",
            "FTCN6",
            "FTCN7",
            "CSRMTVF",
            "S9BTVF"
        ],
        "default": [
            "CS2",
            "CS5",
            "BBSS",
            "RJ140",
            "RJ140A",
            "RBJ150",
            "RBJ167",
            "HBJ",
            "RTT",
            "ADBPJ13VF",
            "ARZFBSL",
            "ARZJ",
            "BBFB",
            "BBFBSL",
            "BBSSVF",
            "CHIFB",
            "CHIFBSL",
            "CJDIVF",
            "CS10CVF",
            "CS5VF",
            "CSSFVF",
            "DFFB",
            "DFFBSL",
            "HAFB",
            "HAFBSL",
            "HOUSFB",
            "HOUSFBSL",
            "HRDFB",
            "HRDFBSL",
            "KLBPJVF",
            "MBFB",
            "MBFBSL",
            "ROAKFB",
            "ROAKFBSL",
            "WINGFB",
            "WINGFBSL",
            "ARZ2B",
            "ARZ2BSL",
            "BB2B",
            "BB2BSL",
            "CHI2B",
            "CHI2BSL",
            "CJP2VF",
            "DF2B",
            "DF2BSL",
            "HA2B",
            "HA2BSL",
            "HOUS2B",
            "HOUS2BSL",
            "HRD2B",
            "HRD2BSL",
            "MB2B",
            "MB2BSL",
            "ROAK2B",
            "ROAK2BSL",
            "WING2B",
            "WING2BSL",
            "ARZVN",
            "ARZVNSL",
            "BBVN",
            "BBVNSL",
            "CHIJ",
            "CHIVNSL",
            "CLNCHJ",
            "CS2VF",
            "DFVN",
            "DFVNSL",
            "HAVN",
            "HAVNSL",
            "HOUSJ",
            "HOUSVNSL",
            "HRDJ",
            "HRDVNSL",
            "MBVN",
            "MBVNSL",
            "NYMJ",
            "RJOAK",
            "ROAKVNSL",
            "WINGVN",
            "WINGVNSL",
            "FTVN1",
            "FTVN2",
            "FTVN3",
            "FTVN4",
            "FTVN5",
            "FTVN6",
            "FTVN7",
            "FTCN1",
            "FTCN2",
            "FTCN3",
            "FTCN4",
            "FTCN5",
            "FTCN6",
            "FTCN7"
        ],
        "bsn": [
            "CS2",
            "CS5",
            "BBSS",
            "RJ140",
            "RJ140A",
            "RBJ150",
            "RBJ167",
            "HBJ",
            "RTT",
            "ADBPJ13VF",
            "ARZFBSL",
            "ARZJ",
            "BBFB",
            "BBFBSL",
            "BBSSVF",
            "CHIFB",
            "CHIFBSL",
            "CJDIVF",
            "CS10CVF",
            "CS5VF",
            "CSSFVF",
            "DFFB",
            "DFFBSL",
            "HAFB",
            "HAFBSL",
            "HOUSFB",
            "HOUSFBSL",
            "HRDFB",
            "HRDFBSL",
            "KUJ",
            "KLBPJVF",
            "MBFB",
            "MBFBSL",
            "ROAKFB",
            "ROAKFBSL",
            "WINGFB",
            "WINGFBSL",
            "ARZ2B",
            "ARZ2BSL",
            "BB2B",
            "BB2BSL",
            "CHI2B",
            "CHI2BSL",
            "CJP2VF",
            "DF2B",
            "DF2BSL",
            "HA2B",
            "HA2BSL",
            "HOUS2B",
            "HOUS2BSL",
            "HRD2B",
            "HRD2BSL",
            "MB2B",
            "MB2BSL",
            "ROAK2B",
            "ROAK2BSL",
            "WING2B",
            "WING2BSL",
            "ARZVN",
            "ARZVNSL",
            "BBVN",
            "BBVNSL",
            "CHIJ",
            "CHIVNSL",
            "CLNCHJ",
            "CS2VF",
            "DFVN",
            "DFVNSL",
            "HAVN",
            "HAVNSL",
            "HOUSJ",
            "HOUSVNSL",
            "HRDJ",
            "HRDVNSL",
            "MBVN",
            "MBVNSL",
            "NYMJ",
            "RJOAK",
            "ROAKVNSL",
            "WINGVN",
            "WINGVNSL",
            "FTVN1",
            "FTVN2",
            "FTVN3",
            "FTVN4",
            "FTVN5",
            "FTVN6",
            "FTVN7",
            "FTCN1",
            "FTCN2",
            "FTCN3",
            "FTCN4",
            "FTCN5",
            "FTCN6",
            "FTCN7"
        ]
    },
    "pants": {
        "all": [
            "CPVN",
            "CPK",
            "CP5",
            "CPRO",
            "CP5MR",
            "CPW",
            "CPROB",
            "CPVP2",
            "KLPHSO",
            "PPU140",
            "PPU140A",
            "PRO150",
            "PRO150P",
            "LNCHSRP",
            "LNCHSR",
            "BPU150",
            "BP150K",
            "LNCHKP",
            "LNCHKPP",
            "BP95MR",
            "PP350MR",
            "BPVP",
            "BP31MR",
            "BEP31",
            "BP31SR",
            "PIN150",
            "BPVP2",
            "BP150",
            "RP150",
            "CP5VF",
            "CP5MRVF",
            "CPVNVF",
            "CPROVF",
            "CPKVF",
            "CPVP2VF",
            "CPROIVF",
            "CPW",
            "CPWVF"
        ],
        "default": [
            "CPK",
            "CPRO",
            "CP5MR",
            "PPU140",
            "PPU140A",
            "PRO150",
            "PRO150P",
            "LNCHSRP",
            "LNCHSR",
            "BPU150",
            "BP150K",
            "LNCHKP",
            "LNCHKPP",
            "PIN150",
            "CP5VF",
            "CPROVF",
            "CPKVF",
            "CPROIVF",
            "CPW",
            "CPWVF"
        ],
        "bsn": [
            "CPK",
            "CPRO",
            "CP5MR",
            "PPU140",
            "PPU140A",
            "PRO150",
            "PRO150P",
            "LNCHSRP",
            "LNCHSR",
            "BPU150",
            "BP150K",
            "LNCHKP",
            "LNCHKPP",
            "PIN150",
            "CP5VF",
            "CPROVF",
            "CPKVF",
            "CPROIVF",
            "CPW",
            "CPWVF"
        ]
    },
    "garments": {
        "all": [
            "CJSB",
            "CS2",
            "CJP2",
            "CSUCPJ",
            "CSSP",
            "CSMPPJ",
            "SS1",
            "CJDIP",
            "CROAD",
            "S9BT",
            "CSRMT",
            "CJ2BI",
            "CSPL",
            "CSSIFJ",
            "CSMPFJ",
            "CJ3F",
            "CJMSI",
            "CSBI",
            "CS5",
            "CS10C",
            "BBSS",
            "CJDI",
            "CSUCFJ",
            "CSSF",
            "CJI",
            "ADBPJ13",
            "KLBPJ",
            "ROAD",
            "RBJ2B",
            "SSBASE",
            "RJFADE",
            "RJ140",
            "RJ140A",
            "RBJ150",
            "RSJ150",
            "RBBJ95",
            "RBBJ350",
            "RBJ167",
            "HBJ",
            "RTT",
            "ADBPJ13VF",
            "ARZFBSL",
            "ARZJ",
            "BBFB",
            "BBFBSL",
            "BBSSVF",
            "CHIFB",
            "CHIFBSL",
            "CJDIVF",
            "CS10CVF",
            "CS5VF",
            "CSSFVF",
            "DFFB",
            "DFFBSL",
            "HAFB",
            "HAFBSL",
            "HOUSFB",
            "HOUSFBSL",
            "HRDFB",
            "HRDFBSL",
            "KUJ",
            "KLBPJVF",
            "MBFB",
            "MBFBSL",
            "ROAKFB",
            "ROAKFBSL",
            "WINGFB",
            "WINGFBSL",
            "ARZ2B",
            "ARZ2BSL",
            "BB2B",
            "BB2BSL",
            "CHI2B",
            "CHI2BSL",
            "CJP2VF",
            "CJ2BIVF",
            "DF2B",
            "DF2BSL",
            "HA2B",
            "HA2BSL",
            "HOUS2B",
            "HOUS2BSL",
            "HRD2B",
            "HRD2BSL",
            "MB2B",
            "MB2BSL",
            "ROAK2B",
            "ROAK2BSL",
            "WING2B",
            "WING2BSL",
            "ARZVN",
            "ARZVNSL",
            "BBVN",
            "BBVNSL",
            "CHIJ",
            "CHIVNSL",
            "CLNCHJ",
            "CS2VF",
            "DFVN",
            "DFVNSL",
            "HAVN",
            "HAVNSL",
            "HOUSJ",
            "HOUSVNSL",
            "HRDJ",
            "HRDVNSL",
            "MBVN",
            "MBVNSL",
            "NYMJ",
            "RJOAK",
            "ROAKVNSL",
            "WINGVN",
            "WINGVNSL",
            "FTVN1",
            "FTVN2",
            "FTVN3",
            "FTVN4",
            "FTVN5",
            "FTVN6",
            "FTVN7",
            "FTCN1",
            "FTCN2",
            "FTCN3",
            "FTCN4",
            "FTCN5",
            "FTCN6",
            "FTCN7",
            "CSRMTVF",
            "S9BTVF",
            "CPVN",
            "CPK",
            "CP5",
            "CPRO",
            "CP5MR",
            "CPW",
            "CPROB",
            "CPVP2",
            "KLPHSO",
            "PPU140",
            "PPU140A",
            "PRO150",
            "PRO150P",
            "LNCHSRP",
            "LNCHSR",
            "BPU150",
            "BP150K",
            "LNCHKP",
            "LNCHKPP",
            "BP95MR",
            "PP350MR",
            "BPVP",
            "BP31MR",
            "BEP31",
            "BP31SR",
            "PIN150",
            "BPVP2",
            "BP150",
            "RP150",
            "CP5VF",
            "CP5MRVF",
            "CPVNVF",
            "CPROVF",
            "CPKVF",
            "CPVP2VF",
            "CPROIVF",
            "CPW",
            "CPWVF"
        ],
        "default": [
            "CS2",
            "CS5",
            "BBSS",
            "RJ140",
            "RJ140A",
            "RBJ150",
            "RBJ167",
            "HBJ",
            "RTT",
            "ADBPJ13VF",
            "ARZFBSL",
            "ARZJ",
            "BBFB",
            "BBFBSL",
            "BBSSVF",
            "CHIFB",
            "CHIFBSL",
            "CJDIVF",
            "CS10CVF",
            "CS5VF",
            "CSSFVF",
            "DFFB",
            "DFFBSL",
            "HAFB",
            "HAFBSL",
            "HOUSFB",
            "HOUSFBSL",
            "HRDFB",
            "HRDFBSL",
            "KLBPJVF",
            "MBFB",
            "MBFBSL",
            "ROAKFB",
            "ROAKFBSL",
            "WINGFB",
            "WINGFBSL",
            "ARZ2B",
            "ARZ2BSL",
            "BB2B",
            "BB2BSL",
            "CHI2B",
            "CHI2BSL",
            "CJP2VF",
            "DF2B",
            "DF2BSL",
            "HA2B",
            "HA2BSL",
            "HOUS2B",
            "HOUS2BSL",
            "HRD2B",
            "HRD2BSL",
            "MB2B",
            "MB2BSL",
            "ROAK2B",
            "ROAK2BSL",
            "WING2B",
            "WING2BSL",
            "ARZVN",
            "ARZVNSL",
            "BBVN",
            "BBVNSL",
            "CHIJ",
            "CHIVNSL",
            "CLNCHJ",
            "CS2VF",
            "DFVN",
            "DFVNSL",
            "HAVN",
            "HAVNSL",
            "HOUSJ",
            "HOUSVNSL",
            "HRDJ",
            "HRDVNSL",
            "MBVN",
            "MBVNSL",
            "NYMJ",
            "RJOAK",
            "ROAKVNSL",
            "WINGVN",
            "WINGVNSL",
            "FTVN1",
            "FTVN2",
            "FTVN3",
            "FTVN4",
            "FTVN5",
            "FTVN6",
            "FTVN7",
            "FTCN1",
            "FTCN2",
            "FTCN3",
            "FTCN4",
            "FTCN5",
            "FTCN6",
            "FTCN7",
            "CPK",
            "CPRO",
            "CP5MR",
            "PPU140",
            "PPU140A",
            "PRO150",
            "PRO150P",
            "LNCHSRP",
            "LNCHSR",
            "BPU150",
            "BP150K",
            "LNCHKP",
            "LNCHKPP",
            "PIN150",
            "CP5VF",
            "CPROVF",
            "CPKVF",
            "CPROIVF",
            "CPW",
            "CPWVF"
        ],
        "bsn": [
            "CS2",
            "CS5",
            "BBSS",
            "RJ140",
            "RJ140A",
            "RBJ150",
            "RBJ167",
            "HBJ",
            "RTT",
            "ADBPJ13VF",
            "ARZFBSL",
            "ARZJ",
            "BBFB",
            "BBFBSL",
            "BBSSVF",
            "CHIFB",
            "CHIFBSL",
            "CJDIVF",
            "CS10CVF",
            "CS5VF",
            "CSSFVF",
            "DFFB",
            "DFFBSL",
            "HAFB",
            "HAFBSL",
            "HOUSFB",
            "HOUSFBSL",
            "HRDFB",
            "HRDFBSL",
            "KUJ",
            "KLBPJVF",
            "MBFB",
            "MBFBSL",
            "ROAKFB",
            "ROAKFBSL",
            "WINGFB",
            "WINGFBSL",
            "ARZ2B",
            "ARZ2BSL",
            "BB2B",
            "BB2BSL",
            "CHI2B",
            "CHI2BSL",
            "CJP2VF",
            "DF2B",
            "DF2BSL",
            "HA2B",
            "HA2BSL",
            "HOUS2B",
            "HOUS2BSL",
            "HRD2B",
            "HRD2BSL",
            "MB2B",
            "MB2BSL",
            "ROAK2B",
            "ROAK2BSL",
            "WING2B",
            "WING2BSL",
            "ARZVN",
            "ARZVNSL",
            "BBVN",
            "BBVNSL",
            "CHIJ",
            "CHIVNSL",
            "CLNCHJ",
            "CS2VF",
            "DFVN",
            "DFVNSL",
            "HAVN",
            "HAVNSL",
            "HOUSJ",
            "HOUSVNSL",
            "HRDJ",
            "HRDVNSL",
            "MBVN",
            "MBVNSL",
            "NYMJ",
            "RJOAK",
            "ROAKVNSL",
            "WINGVN",
            "WINGVNSL",
            "FTVN1",
            "FTVN2",
            "FTVN3",
            "FTVN4",
            "FTVN5",
            "FTVN6",
            "FTVN7",
            "FTCN1",
            "FTCN2",
            "FTCN3",
            "FTCN4",
            "FTCN5",
            "FTCN6",
            "FTCN7",
            "CPK",
            "CPRO",
            "CP5MR",
            "PPU140",
            "PPU140A",
            "PRO150",
            "PRO150P",
            "LNCHSRP",
            "LNCHSR",
            "BPU150",
            "BP150K",
            "LNCHKP",
            "LNCHKPP",
            "PIN150",
            "CP5VF",
            "CPROVF",
            "CPKVF",
            "CPROIVF",
            "CPW",
            "CPWVF"
        ]
    },
    "colors": {
        "customJersey": [
            "GR",
            "B",
            "W",
            "BG",
            "MA",
            "C",
            "BO",
            "LG",
            "P",
            "DG",
            "CB",
            "R",
            "S",
            "N",
            "PU",
            "VG"
        ],
        "customJerseyOptional": [
            "none",
            "GR",
            "B",
            "W",
            "BG",
            "MA",
            "C",
            "BO",
            "LG",
            "P",
            "DG",
            "CB",
            "R",
            "S",
            "N",
            "PU",
            "VG"
        ],
        "RBJ150": [
            "B",
            "BG",
            "DG",
            "N",
            "R",
            "S",
            "W"
        ],
        "RBJ150Optional": [
            "none",
            "B",
            "BG",
            "DG",
            "N",
            "R",
            "S",
            "W"
        ],
        "RBBJ95": [
            "DY-B",
            "DY-N",
            "W-B",
            "W-N"
        ],
        "RBBJ95Optional": [
            "none",
            "DY-B",
            "DY-N",
            "W-B",
            "W-N"
        ],
        "RJ140": [
            "BG",
            "W"
        ],
        "RJ140Optional": [
            "none",
            "BG",
            "W"
        ],
        "RJ140A": [
            "GR"
        ],
        "RJ140AOptional": [
            "none",
            "GR"
        ],
        "RSJ150": [
            "BG",
            "W"
        ],
        "RSJ150Optional": [
            "none",
            "BG",
            "W"
        ],
        "RBBJ350": [
            "BG",
            "W"
        ],
        "RBBJ350Optional": [
            "none",
            "BG",
            "W"
        ],
        "RBJ167": [
            "B",
            "BO",
            "C",
            "DG",
            "MA",
            "N",
            "R",
            "S",
            "VG",
            "W"
        ],
        "RBJ167Optional": [
            "none",
            "B",
            "BO",
            "C",
            "DG",
            "MA",
            "N",
            "R",
            "S",
            "VG",
            "W"
        ],
        "HBJ": [
            "B",
            "C",
            "DG",
            "N",
            "R",
            "S"
        ],
        "HBJOptional": [
            "none",
            "B",
            "C",
            "DG",
            "N",
            "R",
            "S"
        ],
        "ROAD": [
            "B",
            "C",
            "DG",
            "MA",
            "N",
            "PU",
            "R",
            "S",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "ROADOptional": [
            "none",
            "B",
            "C",
            "DG",
            "MA",
            "N",
            "PU",
            "R",
            "S",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "RJFADE": [
            "W-B",
            "W-R",
            "W-S",
            "W-N",
            "W-DG"
        ],
        "RJFADEOptional": [
            "none",
            "W-B",
            "W-R",
            "W-S",
            "W-N",
            "W-DG"
        ],
        "SSBASE": [
            "B",
            "BO",
            "C",
            "CB",
            "DG",
            "GR",
            "LG",
            "MA",
            "N",
            "PU",
            "R",
            "S",
            "W"
        ],
        "SSBASEOptional": [
            "none",
            "B",
            "BO",
            "C",
            "CB",
            "DG",
            "GR",
            "LG",
            "MA",
            "N",
            "PU",
            "R",
            "S",
            "W"
        ],
        "RBJ2B": [
            "B",
            "DG",
            "N",
            "R",
            "S",
            "W"
        ],
        "RBJ2BOptional": [
            "none",
            "B",
            "DG",
            "N",
            "R",
            "S",
            "W"
        ],
        "W2BM": [
            "B",
            "DG",
            "N",
            "PU",
            "R",
            "S"
        ],
        "W2BMOptional": [
            "none",
            "B",
            "DG",
            "N",
            "PU",
            "R",
            "S"
        ],
        "WMFF": [
            "B",
            "N",
            "R",
            "S",
            "MA",
            "DG",
            "PU",
            "W",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "WMFFOptional": [
            "none",
            "B",
            "N",
            "R",
            "S",
            "MA",
            "DG",
            "PU",
            "W",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "button": [
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "C",
            "S",
            "BO",
            "TX",
            "LG",
            "P",
            "KG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "buttonOptional": [
            "none",
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "C",
            "S",
            "BO",
            "TX",
            "LG",
            "P",
            "KG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customPant": [
            "GR",
            "B",
            "W",
            "BG",
            "P",
            "CB",
            "R",
            "S",
            "N"
        ],
        "customPantOptional": [
            "none",
            "GR",
            "B",
            "W",
            "BG",
            "P",
            "CB",
            "R",
            "S",
            "N"
        ],
        "PPU140": [
            "BG",
            "W"
        ],
        "PPU140Optional": [
            "none",
            "BG",
            "W"
        ],
        "BPVP2": [
            "W-B",
            "W-N",
            "W-R",
            "W-S",
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S"
        ],
        "BPVP2Optional": [
            "none",
            "W-B",
            "W-N",
            "W-R",
            "W-S",
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S"
        ],
        "PIN150": [
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "PIN150Optional": [
            "none",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "BP31SR": [
            "BG",
            "W",
            "B"
        ],
        "BP31SROptional": [
            "none",
            "BG",
            "W",
            "B"
        ],
        "PPU140A": [
            "GR"
        ],
        "PPU140AOptional": [
            "none",
            "GR"
        ],
        "PRO150": [
            "BG",
            "W"
        ],
        "PRO150Optional": [
            "none",
            "BG",
            "W"
        ],
        "PRO150P": [
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S",
            "BG-DG",
            "W-B",
            "W-N",
            "W-R",
            "W-S",
            "W-DG"
        ],
        "PRO150POptional": [
            "none",
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S",
            "BG-DG",
            "W-B",
            "W-N",
            "W-R",
            "W-S",
            "W-DG"
        ],
        "LNCHSR": [
            "BG",
            "W",
            "B",
            "GR"
        ],
        "LNCHSROptional": [
            "none",
            "BG",
            "W",
            "B",
            "GR"
        ],
        "LNCHSRP": [
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "LNCHSRPOptional": [
            "none",
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "LNCHKP": [
            "BG",
            "W"
        ],
        "LNCHKPOptional": [
            "none",
            "BG",
            "W"
        ],
        "LNCHKPP": [
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "LNCHKPPOptional": [
            "none",
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S",
            "W-B",
            "W-N",
            "W-R",
            "W-S"
        ],
        "BPU150": [
            "BG",
            "W"
        ],
        "BPU150Optional": [
            "none",
            "BG",
            "W"
        ],
        "BP150K": [
            "BG",
            "W"
        ],
        "BP150KOptional": [
            "none",
            "BG",
            "W"
        ],
        "BP95MR": [
            "DY-B",
            "DY-N",
            "W-B",
            "W-N"
        ],
        "BP95MROptional": [
            "none",
            "DY-B",
            "DY-N",
            "W-B",
            "W-N"
        ],
        "PP350MR": [
            "BG",
            "W"
        ],
        "PP350MROptional": [
            "none",
            "BG",
            "W"
        ],
        "BPVP": [
            "W-B",
            "W-N",
            "W-R",
            "W-S",
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S"
        ],
        "BPVPOptional": [
            "none",
            "W-B",
            "W-N",
            "W-R",
            "W-S",
            "BG-B",
            "BG-N",
            "BG-R",
            "BG-S"
        ],
        "BP31MR": [
            "B",
            "BG",
            "W"
        ],
        "BP31MROptional": [
            "none",
            "B",
            "BG",
            "W"
        ],
        "BEP31": [
            "B",
            "BG",
            "W"
        ],
        "BEP31Optional": [
            "none",
            "B",
            "BG",
            "W"
        ],
        "BP150": [
            "W",
            "BG"
        ],
        "BP150Optional": [
            "none",
            "W",
            "BG"
        ],
        "RP150": [
            "W-S-B",
            "W-B-R",
            "W-B-BO",
            "W-S-N",
            "BG-S-B",
            "BG-B-R",
            "BG-B-BO",
            "BG-S-N"
        ],
        "RP150Optional": [
            "none",
            "W-S-B",
            "W-B-R",
            "W-B-BO",
            "W-S-N",
            "BG-S-B",
            "BG-B-R",
            "BG-B-BO",
            "BG-S-N"
        ],
        "WB150": [
            "B",
            "N",
            "W",
            "BG"
        ],
        "WB150Optional": [
            "none",
            "B",
            "N",
            "W",
            "BG"
        ],
        "WBDP": [
            "B",
            "BG",
            "N",
            "W"
        ],
        "WBDPOptional": [
            "none",
            "B",
            "BG",
            "N",
            "W"
        ],
        "WB150I": [
            "B-W",
            "N-W",
            "W-B",
            "W-R",
            "W-S"
        ],
        "WB150IOptional": [
            "none",
            "B-W",
            "N-W",
            "W-B",
            "W-R",
            "W-S"
        ],
        "braid": [
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "KG",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "braidOptional": [
            "none",
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "KG",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "3_8Braid": [
            "B-S-B",
            "N-S-N",
            "N-W-N",
            "N-LG-N",
            "N-CB-N",
            "R-B-R",
            "R-W-R",
            "R-BO-R",
            "S-B-S",
            "S-W-S",
            "LG-B-LG",
            "PU-W-PU"
        ],
        "3_8BraidOptional": [
            "none",
            "B-S-B",
            "N-S-N",
            "N-W-N",
            "N-LG-N",
            "N-CB-N",
            "R-B-R",
            "R-W-R",
            "R-BO-R",
            "S-B-S",
            "S-W-S",
            "LG-B-LG",
            "PU-W-PU"
        ],
        "knit": [
            "B",
            "W",
            "S",
            "GR",
            "BG",
            "DB",
            "DM",
            "MA",
            "HP",
            "LP",
            "C",
            "SG",
            "TX",
            "BO",
            "TN",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "knitOptional": [
            "none",
            "B",
            "W",
            "S",
            "GR",
            "BG",
            "DB",
            "DM",
            "MA",
            "HP",
            "LP",
            "C",
            "SG",
            "TX",
            "BO",
            "TN",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "ribKnit": [
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "KG",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "ribKnitOptional": [
            "none",
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "KG",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "sublimated": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "embroidery": [
            "B",
            "W",
            "SG",
            "DB",
            "MA",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TO",
            "LG",
            "LT",
            "OG",
            "VG",
            "KG",
            "DG",
            "MB",
            "CB",
            "R",
            "N",
            "PU"
        ],
        "embroideryOptional": [
            "none",
            "B",
            "W",
            "SG",
            "DB",
            "MA",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TO",
            "LG",
            "LT",
            "OG",
            "VG",
            "KG",
            "DG",
            "MB",
            "CB",
            "R",
            "N",
            "PU"
        ],
        "buttons": [
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "C",
            "S",
            "BO",
            "TX",
            "LG",
            "P",
            "KG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG",
            "PINK"
        ],
        "buttonsOptional": [
            "none",
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "MA",
            "C",
            "S",
            "BO",
            "TX",
            "LG",
            "P",
            "KG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG",
            "PINK"
        ],
        "SBPT": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "SBPTOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "RVT": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "REF"
        ],
        "RVTOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "REF"
        ],
        "TG": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW"
        ],
        "TGOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW"
        ],
        "onlyWhite": [
            "W"
        ],
        "onlyWhiteOptional": [
            "none",
            "W"
        ],
        "onlyBlueGray": [
            "BG"
        ],
        "onlyBlueGrayOptional": [
            "none",
            "BG"
        ],
        "RTT": [
            "B",
            "BO",
            "C",
            "CB",
            "DG",
            "GR",
            "LG",
            "MA",
            "N",
            "PU",
            "R",
            "S",
            "W"
        ],
        "RTTOptional": [
            "none",
            "B",
            "BO",
            "C",
            "CB",
            "DG",
            "GR",
            "LG",
            "MA",
            "N",
            "PU",
            "R",
            "S",
            "W"
        ]
    },
    "opPatterns": {
        "all": [
            "none",
            "1color",
            "2color",
            "3color",
            "0125_1",
            "0375_1",
            "0375_3",
            "0375_6",
            "0500_1",
            "0500_2",
            "0500_3",
            "0500_6",
            "0750_1",
            "0750_3",
            "0750_6",
            "1000_1",
            "1000_2",
            "1000_3",
            "1000_6",
            "1500_1",
            "1500_2",
            "1500_3",
            "1500_6"
        ],
        "jersey": [
            "none",
            "1color",
            "2color",
            "3color"
        ],
        "op4": [
            "none",
            "1color",
            "2color",
            "3color"
        ],
        "op15": [
            "none",
            "1color",
            "2color",
            "3color"
        ]
    },
    "ribKnitPatterns": {
        "all": [
            "none",
            "1inch1color",
            "1inch3color",
            "2inch1color",
            "2inch3color"
        ],
        "cuff": [
            "none",
            "1inch1color",
            "1inch3color",
            "2inch1color",
            "2inch3color"
        ],
        "RIBWST": [
            "none",
            "2inch1color",
            "2inch3color"
        ],
        "collar": [
            "1inch1color",
            "1inch3color"
        ]
    },
    "sleeveStripes": {
        "all": []
    },
    "designPatterns": {
        "all": [
            "solidColor",
            "argyle",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "carbonBlock",
            "carbonFiber",
            "check",
            "check5",
            "digitalCamo",
            "digitalWave",
            "electric",
            "fade",
            "flannel",
            "geo",
            "gradientDots",
            "gradientPlaid",
            "hound",
            "plaid",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "tieDye",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth",
            "rvfj4",
            "rvfj7",
            "rvfj8",
            "rvfjfg",
            "rvfjmz",
            "ohio",
            "streak",
            "predator",
            "bearcat",
            "wingback",
            "aggie",
            "flag",
            "digitalWave-element",
            "tigerStripe",
            "ribKnit",
            "ribKnitPattern01",
            "ribKnitPattern02",
            "ribKnitPattern03",
            "ribKnitPattern06",
            "pattern01",
            "pattern02",
            "pattern03",
            "pattern06"
        ],
        "solidColors": [
            "solidColor"
        ],
        "standard": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "gradientDots",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "noFade": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "digitalCamo",
            "electric",
            "flannel",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "jerseyBody": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "camo",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "proStripe",
            "splat",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "jerseyBodyNoFade": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "camo",
            "digitalCamo",
            "electric",
            "flannel",
            "proStripe",
            "splat",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "pantBody": [
            "solidColor",
            "flannel",
            "proStripe",
            "triadStripe",
            "heather"
        ],
        "patternAndStandard": [
            "pattern01",
            "pattern02",
            "pattern03",
            "pattern06",
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "gradientDots",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "pattern": [
            "pattern01",
            "pattern03",
            "pattern06"
        ]
    },
    "logos": {
        "all": [
            "USFLAG",
            "7ON7",
            "BASEBALL1",
            "BASEBALL2",
            "BASEBALL3",
            "BASEBALL4",
            "BASEBALL5",
            "BASEBALL6",
            "BASKETBALL1",
            "BASKETBALL2",
            "BASKETBALL3",
            "BASKETBALL4",
            "BEAR1",
            "BEAR2",
            "BEAR3",
            "BEAR4",
            "BEAR5",
            "BEAR6",
            "BIRD1",
            "BIRD2",
            "BIRD3",
            "BIRD4",
            "BIRD5",
            "BIRD6",
            "BIRD7",
            "BIRD8",
            "BIRD9",
            "BIRD10",
            "BIRD11",
            "BIRD12",
            "BIRD13",
            "BIRD14",
            "BIRD15",
            "BIRD16",
            "BIRD17",
            "BIRD18",
            "BIRD19",
            "BIRD20",
            "BIRD21",
            "BIRD22",
            "BIRD23",
            "BIRD24",
            "BIRD25",
            "BIRD26",
            "BIRD27",
            "BIRD28",
            "BIRD29",
            "BIRD30",
            "BRAVE1",
            "BRAVE2",
            "BUCCANEER",
            "BUFFALO",
            "BULLDOG1",
            "BULLDOG2",
            "BULLDOG3",
            "BULLDOG4",
            "BULLDOG5",
            "BULLDOG6",
            "BULLDOG7",
            "BULLDOG8",
            "BULLDOG9",
            "BULLDOG10",
            "CARDINAL1",
            "CARDINAL2",
            "CAT1",
            "CAT2",
            "CAT3",
            "CAT4",
            "CAT5",
            "CAT6",
            "CAT7",
            "CAT8",
            "CAT9",
            "CAT10",
            "CAT11",
            "CAT12",
            "CAT13",
            "CAT14",
            "CAT15",
            "CAT16",
            "CAT17",
            "CAT18",
            "CAT19",
            "CAVALIER1",
            "CAVALIER2",
            "CAVALIER3",
            "CHIEF",
            "COBRA",
            "COMICTROJAN",
            "COUGAR",
            "DEVIL",
            "DEVIL2",
            "DEVIL3",
            "DEVIL4",
            "DEVIL5",
            "DRAGON",
            "DRAGONH13",
            "DRAGONI13",
            "EAGLE1",
            "EAGLE2",
            "EAGLE3",
            "EAGLE4",
            "EAGLE5",
            "FOOTBALL1",
            "FOOTBALL2",
            "FOOTBALL3",
            "FOOTBALL4",
            "FOOTBALL5",
            "HELMET",
            "HORSE1",
            "HORSE10",
            "HORSE2",
            "HORSE3",
            "HORSE4",
            "HORSE5",
            "HORSE6",
            "HORSE7",
            "HORSE8",
            "HORSE9",
            "HUSKYF1",
            "INDIAN1",
            "INDIAN2",
            "INDIAN3",
            "INDIAN4",
            "INDIAN5",
            "INDIAN6",
            "INDIAN7",
            "INDIAN8",
            "INDIAN9",
            "INDIAN10",
            "INDIAN11",
            "INDIAN12",
            "INDIAN13",
            "INDIAN14",
            "INDIAN15",
            "INSECT1",
            "INSECT2",
            "INSECT3",
            "INSECT4",
            "INSECT5",
            "KNIGHT1",
            "KNIGHT2",
            "KNIGHT3",
            "KNIGHT4",
            "KNIGHT5",
            "KNIGHT6",
            "KNIGHT7",
            "KNIGHT8",
            "KNIGHT9",
            "KNIGHT10",
            "LIGHTNING1",
            "LIGHTNING2",
            "LIGHTNING3",
            "LIGHTNING4",
            "LION1",
            "LION2",
            "LION3",
            "LION4",
            "LION5",
            "LION6",
            "LION7",
            "LION8",
            "LONGHORNS",
            "MARLIN",
            "MISC1",
            "MISC2",
            "MISC3",
            "MISC4",
            "MISC5",
            "MISC6",
            "MISC7",
            "MISC8",
            "MISC9",
            "MISC10",
            "MISC11",
            "MISC12",
            "MISC13",
            "MISC14",
            "MISC15",
            "MISC16",
            "MISC17",
            "MISC18",
            "MISC19",
            "MISC20",
            "MISC21",
            "MISC22",
            "MISC23",
            "MUSTANG1",
            "MUSTANG2",
            "PANTHER1",
            "PANTHER2",
            "PAW1",
            "PAW2",
            "PAW3",
            "PAW4",
            "PAW5",
            "PAW6",
            "PAW7",
            "PAW8",
            "PAW9",
            "PAW10",
            "PAW11",
            "PAW12",
            "PAW13",
            "PAW14",
            "PAW15",
            "PAW16",
            "PAW17",
            "PAW18",
            "PIRATE1",
            "PIRATE2",
            "PIRATE3",
            "PIRATE4",
            "PIRATE5",
            "PIRATE6",
            "PIRATE7",
            "PIRATE8",
            "PIRATE9",
            "PIRATE10",
            "PIRATE11",
            "PIRATE12",
            "PIRATE13",
            "RAM1",
            "RAM2",
            "REBEL",
            "SHAMROCK1",
            "SHAMROCK2",
            "SHARK1",
            "SNAKE1",
            "SNAKE2",
            "SNAKE3",
            "SPARTAN1",
            "SPARTAN2",
            "SPARTAN3",
            "SPARTAN4",
            "SPARTAN5",
            "SPARTAN6",
            "SPARTAN7",
            "SPARTAN8",
            "SPARTAN9",
            "SPARTAN10",
            "SPARTAN11",
            "SPARTAN12",
            "SPARTAN13",
            "SPARTAN14",
            "STAR1",
            "STAR2",
            "STAR3",
            "STAR4",
            "STAR5",
            "TIGER1",
            "TIGER2",
            "TIGER3",
            "VIKING1",
            "VIKING2",
            "VIKING3",
            "VIKING4",
            "VIKING5",
            "VIKING6",
            "WILDCAT1",
            "WILDCAT2",
            "WILDCAT3",
            "WOLF",
            "WOLF1",
            "WOLF2",
            "WOLF3",
            "WOLF4",
            "WOLF5"
        ],
        "watermark": [
            "7ON7",
            "BASEBALL4",
            "BASKETBALL3",
            "BEAR2",
            "BIRD15",
            "BIRD16",
            "BIRD24",
            "BIRD25",
            "BIRD30",
            "CAT9",
            "DRAGONH13",
            "DRAGONI13",
            "HORSE7",
            "HORSE8",
            "HORSE9",
            "INDIAN3",
            "INDIAN4",
            "INDIAN6",
            "INDIAN7",
            "INSECT4",
            "KNIGHT3",
            "KNIGHT5",
            "MISC2",
            "MISC14",
            "MISC16",
            "MISC17",
            "MISC20",
            "MISC22",
            "MISC23",
            "PAW1",
            "PAW3",
            "PAW4",
            "PAW5",
            "PAW7",
            "PAW8",
            "PAW9",
            "PAW10",
            "PAW11",
            "PAW13",
            "PAW14",
            "PAW15",
            "PAW16",
            "PIRATE11",
            "PIRATE12",
            "SHAMROCK1",
            "SHAMROCK2",
            "SNAKE1",
            "SPARTAN9",
            "SPARTAN10",
            "SPARTAN11",
            "SPARTAN14",
            "STAR1",
            "STAR4",
            "STAR5",
            "WOLF2"
        ]
    },
    "tabs": {
        "all": [
            "select",
            "fabricColors",
            "options",
            "decorate"
        ]
    },
    "clothingCollections": {
        "all": [
            "customJersey",
            "stockJersey",
            "sublimatedFullButtonJersey",
            "sublimatedTwoButtonJersey",
            "sublimatedVNeckJersey",
            "fasttrackSublimatedJerseys",
            "sublimatedJersey",
            "customPants",
            "stockPants",
            "sublimatedPants"
        ]
    },
    "clothingTypes": {
        "all": [
            "jersey",
            "pant"
        ]
    },
    "areaFillExceptions": {
        "all": [
            "CSPL",
            "ROAD",
            "CSUCPJ",
            "CSUCFJ",
            "FTVN5",
            "FTVN6",
            "FTVN7",
            "FTCN5",
            "FTCN6",
            "FTCN7",
            "NYMJ",
            "HBJ",
            "CPROIVF"
        ]
    },
    "decoSets": {
        "all": [
            "default",
            "sbpt",
            "rvt",
            "tg",
            "tgYouth",
            "vf",
            "fullSublimation",
            "fullSublimationCHI",
            "fasttrack",
            "pantDeco",
            "none"
        ]
    },
    "decos": {
        "all": [
            "jersey"
        ]
    },
    "decoSizes": {
        "all": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "8",
            "10",
            "12",
            "0.125",
            "0.375",
            "0.75",
            "1.5",
            "2.5",
            "3.5"
        ]
    },
    "decoOptions": {
        "all": [
            "OP1",
            "OP2",
            "OP2_5",
            "OP3",
            "OP4",
            "OP4B",
            "OP4C",
            "OP15",
            "RIBWST",
            "ribKnitCollar",
            "ribKnitCuff",
            "sublimatedCollar",
            "sublimatedCuff",
            "buttonColor",
            "logoColor"
        ]
    },
    "decoPlacements": {
        "all": [
            "1",
            "2",
            "3",
            "4",
            "6",
            "14",
            "30",
            "none",
            "17L",
            "17R",
            "5B",
            "30B"
        ]
    },
    "layouts": {
        "all": [
            "straight",
            "angled",
            "verticalArch",
            "slanted",
            "bridgedArch"
        ]
    },
    "cutTypes": {
        "all": [
            "adult",
            "youth"
        ]
    },
    "sizes": {
        "all": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "8",
            "10",
            "12",
            "0.125",
            "0.375",
            "0.75",
            "1.5",
            "2.5",
            "3.5"
        ]
    }
}