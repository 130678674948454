module.exports={
    "jersey": {
        "teamName": {
            "name": "Team Name",
            "clothingType": "jersey",
            "code": "teamName",
            "contentType": "text",
            "placementList": [
                "none",
                "2"
            ],
            "sizeList": [
                "3",
                "4"
            ],
            "configurationList": [
                "straight",
                "verticalArch",
                "angled",
                "slanted",
                "bridgedArch"
            ],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 15
        },
        "teamNameTailing": {
            "name": "Team Name Tailing",
            "clothingType": "jersey",
            "code": "teamNameTailing",
            "contentType": "text",
            "placementList": [],
            "sizeList": [
                "1",
                "0.75"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 15
        },
        "frontNumber": {
            "name": "Front Number",
            "clothingType": "jersey",
            "code": "frontNumber",
            "contentType": "text",
            "placementList": [
                "none",
                "4",
                "6"
            ],
            "sizeList": [
                "3",
                "4"
            ],
            "configurationList": [],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 2
        },
        "backNumber": {
            "name": "Back Number",
            "clothingType": "jersey",
            "code": "backNumber",
            "contentType": "text",
            "placementList": [
                "none",
                "30"
            ],
            "sizeList": [
                "6",
                "8"
            ],
            "configurationList": [],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 2
        },
        "sleeveNumber": {
            "name": "Sleeve Number",
            "clothingType": "jersey",
            "code": "sleeveNumber",
            "contentType": "text",
            "placementList": [
                "none",
                "17L",
                "17R"
            ],
            "sizeList": [
                "3",
                "4"
            ],
            "configurationList": [],
            "align": "top",
            "fitMode": "fitHeight",
            "maxCharCount": 2
        },
        "playerName": {
            "name": "Player Name",
            "clothingType": "jersey",
            "code": "playerName",
            "contentType": "text",
            "placementList": [
                "none",
                "14"
            ],
            "sizeList": [
                "2",
                "2.5"
            ],
            "configurationList": [
                "straight",
                "verticalArch"
            ],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 15
        },
        "watermarkText": {
            "name": "Watermark Text",
            "clothingType": "jersey",
            "code": "watermarkText",
            "contentType": "text",
            "placementList": [
                "none",
                "5B",
                "30B"
            ],
            "sizeList": [
                "6",
                "8",
                "10",
                "12"
            ],
            "configurationList": [
                "straight"
            ],
            "align": "center",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 3
        },
        "watermarkLogo": {
            "name": "Watermark Logo",
            "clothingType": "jersey",
            "code": "watermarkLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "5B",
                "30B"
            ],
            "sizeList": [
                "6",
                "8",
                "10",
                "12"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null
        },
        "customLogo": {
            "name": "Custom Logo",
            "clothingType": "jersey",
            "code": "customLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "1",
                "2",
                "3",
                "4",
                "6",
                "17L",
                "17R",
                "14"
            ],
            "sizeList": [
                "2",
                "3",
                "4"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null
        },
        "stockLogo": {
            "name": "Stock Logo",
            "clothingType": "jersey",
            "code": "stockLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "1",
                "2",
                "3",
                "4",
                "6",
                "17L",
                "17R",
                "14"
            ],
            "sizeList": [
                "2",
                "3",
                "4"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null
        }
    }
}