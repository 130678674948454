module.exports={
    "OP1": {
        "code": "OP1",
        "name": "OP1 Braid (Around neck, down front)"
    },
    "OP2": {
        "code": "OP2",
        "name": "OP2 Braid (Around edge of neck)"
    },
    "OP2_5": {
        "code": "OP2_5",
        "name": "OP2.5 Braid (Around edge of neck)"
    },
    "OP3": {
        "code": "OP3",
        "name": "OP3 Braid (Sleeve end/armhole)"
    },
    "OP4": {
        "code": "OP4",
        "name": "Decoration down sides (front) (OP4)"
    },
    "OP4B": {
        "code": "OP4B",
        "name": "Decoration down sides (back) (OP4B)"
    },
    "OP4C": {
        "code": "OP4C",
        "name": "Decoration down sides (front&back) (OP4C)"
    },
    "OP15": {
        "code": "OP15",
        "name": "Decoration around belt tunnels (OP15)"
    },
    "RIBWST": {
        "code": "RIBWST",
        "name": "Rib Knit Waist Band"
    },
    "ribKnitCollar": {
        "code": "ribKnitCollar",
        "name": "Rib Knit Collar"
    },
    "ribKnitCuff": {
        "code": "ribKnitCuff",
        "name": "Rib Knit Cuff"
    },
    "sublimatedCollar": {
        "code": "sublimatedCollar",
        "name": "Sublimated Collar"
    },
    "sublimatedCuff": {
        "code": "sublimatedCuff",
        "name": "Sublimated Cuff"
    },
    "buttonColor": {
        "code": "buttonColor",
        "name": "Button Color"
    },
    "logoColor": {
        "code": "logoColor",
        "name": "Rawlings Logo Color"
    }
}