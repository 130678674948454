module.exports={
    "customJersey": {
        "code": "customJersey",
        "name": "Custom Jersey"
    },
    "stockJersey": {
        "code": "stockJersey",
        "name": "Stock Jersey"
    },
    "sublimatedFullButtonJersey": {
        "code": "sublimatedFullButtonJersey",
        "name": "Sublimated Full Button Jersey"
    },
    "sublimatedTwoButtonJersey": {
        "code": "sublimatedTwoButtonJersey",
        "name": "Sublimated Two Button Jersey"
    },
    "sublimatedVNeckJersey": {
        "code": "sublimatedVNeckJersey",
        "name": "Sublimated V-Neck Jersey"
    },
    "fasttrackSublimatedJerseys": {
        "code": "fasttrackSublimatedJerseys",
        "name": "Fasttrack Sublimated Jerseys"
    },
    "sublimatedJersey": {
        "code": "sublimatedJersey",
        "name": "Sublimated Jersey"
    },
    "customPants": {
        "code": "customPants",
        "name": "Custom Pants"
    },
    "stockPants": {
        "code": "stockPants",
        "name": "Stock Pants"
    },
    "sublimatedPants": {
        "code": "sublimatedPants",
        "name": "Sublimated Pants"
    }
}