module.exports={
    "CSPL": [
        {
            "code": "CSPL",
            "area": "area1",
            "colorSet": "braid",
            "patterns": []
        }
    ],
    "ROAD": [
        {
            "code": "ROAD",
            "area": "area1",
            "colorSet": "braid",
            "patterns": []
        }
    ],
    "CSUCPJ": [
        {
            "code": "CSUCPJ",
            "area": "area1",
            "colorSet": "braid",
            "patterns": []
        },
        {
            "code": "CSUCPJ",
            "area": "area5",
            "colorSet": "braid",
            "patterns": []
        }
    ],
    "CSUCFJ": [
        {
            "code": "CSUCFJ",
            "area": "area1",
            "colorSet": "braid",
            "patterns": []
        },
        {
            "code": "CSUCFJ",
            "area": "area5",
            "colorSet": "braid",
            "patterns": []
        }
    ],
    "FTVN5": [
        {
            "code": "FTVN5",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "fade"
            ]
        },
        {
            "code": "FTVN5",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "fade"
            ]
        }
    ],
    "FTVN6": [
        {
            "code": "FTVN6",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTVN6",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTVN6",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTVN6",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTVN6",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        }
    ],
    "FTVN7": [
        {
            "code": "FTVN7",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTVN7",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTVN7",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTVN7",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTVN7",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        }
    ],
    "FTCN5": [
        {
            "code": "FTCN5",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "fade"
            ]
        },
        {
            "code": "FTCN5",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "fade"
            ]
        }
    ],
    "FTCN6": [
        {
            "code": "FTCN6",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTCN6",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTCN6",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTCN6",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        },
        {
            "code": "FTCN6",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ]
        }
    ],
    "FTCN7": [
        {
            "code": "FTCN7",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTCN7",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTCN7",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTCN7",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        },
        {
            "code": "FTCN7",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ]
        }
    ],
    "NYMJ": [
        {
            "code": "NYMJ",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "proStripe",
                "triadStripe"
            ]
        }
    ],
    "HBJ": [
        {
            "code": "HBJ",
            "area": "area1",
            "colorSet": "onlyWhite",
            "patterns": []
        },
        {
            "code": "HBJ",
            "area": "area3",
            "colorSet": "onlyBlueGray",
            "patterns": []
        }
    ],
    "CPROIVF": [
        {
            "code": "CPROIVF",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "proStripe",
                "heather",
                "flannel",
                "triadStripe"
            ]
        }
    ]
}