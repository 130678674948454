exports.tabs = require('./sheets/tabs.json')
exports.toggles = require('./sheets/toggles.json')
exports.fonts = require('./sheets/fonts.json')
exports.clothingCollections = require('./sheets/clothingCollections.json')
exports.clothingTypes = require('./sheets/clothingTypes.json')
exports.garments = require('./sheets/garments.json')
exports.areaFillExceptions = require('./sheets/areaFillExceptions.json')
exports.colors = require('./sheets/colors.json')
exports.decoSets = require('./sheets/decoSets.json')
exports.opPatterns = require('./sheets/opPatterns.json')
exports.sleeveStripes = require('./sheets/sleeveStripes.json')
exports.designPatterns = require('./sheets/designPatterns.json')
exports.decos = require('./sheets/decos.json')
exports.logos = require('./sheets/logos.json')
exports.decoSizes = require('./sheets/decoSizes.json')
exports.decoOptions = require('./sheets/decoOptions.json')
exports.ribKnitPatterns = require('./sheets/ribKnitPatterns.json')
exports.decoPlacements = require('./sheets/decoPlacements.json')
exports.layouts = require('./sheets/layouts.json')
exports.cutTypes = require('./sheets/cutTypes.json')
exports.sizes = require('./sheets/sizes.json')